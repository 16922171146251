import React from 'react'
import { Route, Routes } from "react-router-dom";
import Overview from '../Components/Admin/Overview/Overview';


/**
 * 
 * @export
 * @function OverviewRouter
 * @description Router for roles routes 
 */
function OverviewRouter(props) {
    return (
        <Routes>
            <Route path='/' element={<Overview />} />
        </Routes>
    )
}

export default OverviewRouter