import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import axios from 'axios'

const { Option } = Select 


/**
 * @const SelectProyecto
 * @description Select para los proyectos registrados en el sistema
 */
const SelectProyecto = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el Proyecto", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        size="default",
        suffixIcon=null,
        mode = null
    } = props


    const [ proyectos, setProyectos ] = useState({
        data: [],
        page: 1,
        limit: 50,
        total: 0,
        search: null,
    })


    /**
     * @const getProyectos
     * @description Obtiene los proyectos
     */
    const getProyectos = ({page, limit, search} = proyectos) => {

        axios.get('/proyectos', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setProyectos(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los proyectos')
        })
    }

    //componentDidMount
    useEffect(() => {
        getProyectos()
    }, [])


    return (
        <Select
            size={size}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search)=> getProyectos({search})}
            onSelect={(inmueble)=> {
                if(mode === null){
                    onChange(inmueble.value)
                    onSelect(inmueble.value)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple"){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            suffixIcon={suffixIcon ? <UserOutlined style={{fontSize: '18px'}}/> : null}
            options={proyectos?.data?.map(({_id, nombre}) => ({value: _id, label: nombre}))}
            {...props}
        >
        </Select>
    );
}



export default SelectProyecto;