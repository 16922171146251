import React, { Component, useContext, forwardRef } from "react";
import { Row, Col, Upload, Form, Button, message, Space, Input } from "antd";
import axios from "axios";

import MentionsCustomized from './Menciones';
import MessagesList from "./MessageList";
import Socket from "../../../../Hooks/Socket";
import { User } from "../../../../Hooks/Logged";
import FilesBox from "./FilesView";
import { IconClip } from "../../../Widgets/Iconos";
import ModalVisitas from "./ModalVisitas";

import { CalendarOutlined } from "@ant-design/icons";
import { TbBrowserCheck } from "react-icons/tb";
import { PlusOutlined } from "@ant-design/icons"
import FloatingButton from "../../../Widgets/FloatingButton/FloatingButton"
import ModalFeedback from "./ModalFeedback";
import moment from "moment-timezone"


/**
 *
 *
 * @class Actualizaciones
 * @extends {Component}
 */
class Actualizaciones extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            edit: false,

            comment: "",
            asesores: {
                data: [],
                page: 1,
                total: 0
            },
            proyectos: {
                data: [],
                page: 1,
                total: 0
            },
            actividad: {
                data: [],
                page: 1,
                pages: 1,
                limit: 25,
                total: 0
            },
            filesUploaded: [],
            modal_visita: false,
            push: false,
            cita: {
                _id: null,
                prospecto_id: null,
                duracion: 0,
                estatus: 1,
                descripcion: "",
                fecha: moment(),
                hora: 0,
                minuto: 0
            }
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        this.getAsesores()

        console.log('props updates', this.props)
        this.setState({ prospecto_id: this.props.prospecto_id })
        //ingreso al room del chat para el intercambio de datos y cargo la ultima cita creada
        if (this.props.prospecto_id) {
            this.getCita()
            this.props.socket.emit('/admin/prospecto/join', this.props.prospecto_id)
        }

        //al recibir un mensaje, lo agrego 
        this.props.socket.on('/admin/prospecto/message/add', this.IO_addMessage)

        //al elimiar un mensaje, lo quito
        this.props.socket.on('/admin/prospecto/message/delete', this.IO_deleteMessage)

        //al actualizar un mensaje lo actualizo
        this.props.socket.on('/admin/prospecto/message/update', this.IO_updateMessage)

        //al cargar mas mensajes 
        this.props.socket.on('/admin/prospecto/message/list', this.IO_loadActualizaciones)

        //por si ocurre un error
        this.props.socket.on('error', this.IO_Error)

        this.props.socket.on('sucessful', this.IO_loadActualizaciones)


    }

    componentWillUnmount() {
        //salir de la sala del prospecto
        console.log('saliendo de actualizaciones...')
        if (this.props.prospecto_id)
            this.props.socket.emit('/admin/prospecto/leave', this.props.prospecto_id)

    }

    IO_connect = (id) => {
        console.log('realizando conexion al socket del prospecto recien creado', id)
        this.props.socket.emit('/admin/prospecto/join', id)
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @method IO_Error
     * @description Muestra los errores que pudieran ocurrir en el socket 
     */
    IO_Error = (response) => {
        message.error(response.message)
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Envia la peticion para cargar los mensajes del chat
     */
    IO_getActualizaciones = (
        {
            page = this.state.actividad.page,
            limit = this.state.actividad.limit,

        } = this.state.actividad,
    ) => {

        this.setState({ loading: true })
        this.props.socket.emit('/admin/prospecto/messages', { page: page, limit: limit, id: this.props.prospecto_id })

    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description actualiza el state con los mensajes obtenidos del socket
     */
    IO_loadActualizaciones = (res) => {

        this.setState({ loading: true })
        let old = this.state.actividad;

        let data = (res.page == 1) ? res.data : [...res.data, ...old.data];

        let new_info = {
            data: data,
            page: res.page,
            limit: res.limit,
            pages: res.pages,
            total: res.total
        }
        this.setState({
            actividad: new_info,
            loading: false,
            push: false
        });
    }

    /**
    *
    *
    * @memberof ProspectoModal
    * @description Obtiene la lista de asesores
    */
    getAsesores = ({
        page = this.state.asesores.page,
        limit = this.state.asesores.limit,
    } = this.state.asesores) => {
        axios.get('/asesores', {
            params: {
                page,
                limit,
                proyecto_id: this.props.proyecto, //asesores que tengan asignado el proyecto actual
            }
        })
            .then(({ data }) => {
                this.setState({
                    asesores: {
                        ...data,
                        loading: false,
                    },
                });

            })
            .catch(error => {
                console.log('error', error)
            })
            .finally(f => {
                this.setState({ searching: false })
            })
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Obtiene la ultima cita registrada al prospecto
     */
    getCita = () => {
        axios.get('/prospectos/citas/' + this.props.prospecto_id)
            .then(({ data }) => {

                console.log('data citas', data)
                if (data) {
                    let hora = moment(data.fecha).hour()
                    let minuto = moment(data.fecha).minute()

                    this.setState(state => {
                        state.cita = data
                        state.cita.hora = hora
                        state.cita.minuto = minuto

                        return state
                    })
                }
                else {
                    let cita = {
                        _id: null,
                        prospecto_id: null,
                        duracion: 0,
                        estatus: 1,
                        descripcion: "",
                        fecha: moment(),
                        hora: 0,
                        minuto: 0
                    }
                    this.setState({ cita: cita })

                }
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Añade el mensaje al state de mensajes del chat
     */
    IO_addMessage = (message) => {

        let { actividad } = this.state
        actividad.data = [
            ...actividad.data,
            message,
        ]
        this.setState({ actividad: { ...actividad }, push: true })
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Elimina un mensaje del state de mensajes del chat
     */
    IO_deleteMessage = (message_deleted) => {
        let actividad_messages = this.state.actividad;
        let index_todelete = actividad_messages.data.findIndex(m => m._id === message_deleted);

        if (index_todelete != -1) {
            actividad_messages.data.splice(index_todelete, 1);
            this.setState({ actividad: actividad_messages, push: false })
        }
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Actualiza un mensaje del state de mensajes del chat
     */
    IO_updateMessage = (message) => {
        message.usuario = this.props.user;
        let { actividad } = this.state
        let index_toupdate = actividad.data.findIndex(m => m._id == message._id);
        actividad.data[index_toupdate] = message;
        this.setState({ actividad: { ...actividad }, push: false })
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Manda la peticion para borrar un mensaje por sockets
     */
    deleteMessage = (id) => {
        this.props.socket.emit('/admin/prospecto/message/delete', { prospecto_id: this.props.prospecto_id, message_id: id })
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Actualiza el formulario y el state con la informacion del mensaje a editar
     */
    updateMessage = (id) => {
        let { actividad } = this.state;
        let messages = actividad.data;
        let message_index = messages.findIndex(message => message._id == id);
        let message = messages[message_index];

        this.formRef.setFieldsValue({ _id: message._id, entrada: message.entrada })
        this.setState({ comment: message.entrada, filesUploaded: message.files, edit: true })
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Carga mas mensajes en el chat
     */
    loadMore = () => {
        let actividad = this.state.actividad;
        let actual = actividad.page;
        let maxima = actividad.pages;

        if (actual < maxima)
            this.IO_getActualizaciones({ page: actual + 1 })
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @descritpion Actualiza el state con los archivos enviados al servidor
     */
    addFileState = (file) => {
        let filesUploaded = this.state.filesUploaded;
        filesUploaded.push(file)
        this.setState(filesUploaded)
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Muestra mensajes de si el archivo ya se subio o no
     */
    onChangeFiles = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Envio de archivos al añadirlo al upload
     */
    uploadFile = (value) => {
        const formData = new FormData()

        formData.append('file', value.file);
        formData.append('prospecto_id', this.props.prospecto_id);
        axios.post('/prospectos/file', formData)
            .then(r => {
                console.log('r', r)
                this.addFileState(r.data)
            })
            .catch(e => message.error('No se subio'))
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Elimina un archivo
     */
    deleteFile = (filename, uploadId) => {

        this.props.socket.emit("/admin/prospecto/message/delete/file", { prospecto_id: this.props.prospecto_id, file: filename })

        let filesUploaded = this.state.filesUploaded;
        filesUploaded.splice(uploadId, 1)
        this.setState(filesUploaded)
    }

    /**
    *
    *
    * @memberof Actualizaciones
    * @description Añade un mensaje al chat del prospecto
    */
    add = async (values) => {

        if ((values.entrada && values.entrada.length > 0) || this.state.filesUploaded.length > 0) {
            this.setState({ filesUploaded: [], comment: "", })
            this.formRef.setFieldsValue({ entrada: '' })
            this.props.socket.emit("/admin/prospecto/message/add", {
                prospecto_id: this.props.prospecto_id,
                entrada: values.entrada,
                usuario: this.props.user._id,
                files: this.state.filesUploaded
            })
        }
    };

    /**
    *
    *
    * @memberof Actualizaciones
    * @description Edita un mensaje al chat del prospecto
    */
    update = async (values) => {

        if ((values.entrada && values.entrada.length > 0) || this.state.filesUploaded.length > 0) {
            this.setState({ filesUploaded: [], comment: "", })
            this.formRef.setFieldsValue({ entrada: '' })
            this.props.socket.emit("/admin/prospecto/message/update", {
                message_id: values._id,
                prospecto_id: this.props.prospecto_id,
                entrada: values.entrada,
                files: this.state.filesUploaded
            })
        }
    };

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Limpia los datos del comentario
     */
    cleanData = () => {
        this.setState({ comment: '', filesUploaded: [], edit: false })
        this.formRef.setFieldsValue({ entrada: "" })
    }


    /**
     *
     *
     * @memberof Actualizaciones
     */
    validateDate = () => {

        let cita = this.state.cita;

        let fecha_hoy = moment();
        let fecha_cita = moment(cita.fecha).add(1, "hour");

        //si la cita es despues a hoy, deshabilitar
        if (fecha_cita.isAfter(fecha_hoy))
            return true
        //si la cita es hoy y la hora de la cita es mayor a la actual, habilitar 
        if (fecha_hoy.diff(fecha_cita) == 0 && fecha_cita.hour() > fecha_hoy.hour())
            return false

        return true
    }

    /**
     *
     *
     * @memberof Actualizaciones
     * @description Al hacer submit al comentario se edita o se crea segun el caso
     */
    submit = (values) => {
        if (!this.state.edit)
            this.add(values)
        else
            this.update(values)
    }

    render() {

        return (
            <>
                <div className="container-chat width-100  " >
                    <div className="message-box" >
                        <MessagesList
                            data={this.state.actividad.data}
                            loading={this.state.loading}
                            className={this.props.className}
                            prospecto_id={this.props.prospecto_id}
                            loadMore={this.loadMore}
                            push={this.state.push}
                            deleteMessage={this.deleteMessage}
                            updateMessage={this.updateMessage}
                        />
                    </div>
                    <div className="files-box">
                        <FilesBox
                            files={this.state.filesUploaded}
                            deleteFile={this.deleteFile}
                            id={this.props.prospecto_id}
                            edit={true}
                        />
                    </div>
                    <div className="bottom-content width-100">
                        <Form
                            ref={e => this.formRef = e}
                            onFinish={this.submit}
                            className="form-comment"
                            layout="inline"
                        >
                            <Form.Item name="_id" noStyle >
                                <Input type="hidden" />
                            </Form.Item>
                            <Row justify={"space-between"} className="width-100">
                                <Col className="center" span={2}>
                                    <Form.Item name="adjuntos">
                                        <Upload
                                            customRequest={this.uploadFile}
                                            onChange={this.onChangeFiles}
                                            className="uploader"
                                            showUploadList={false}
                                            maxCount={5}
                                        >
                                            <IconClip />
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col className="center" span={this.state.edit ? 14 : 16}>
                                    <Form.Item name="entrada" className="mentions-form-item">
                                        <MentionsCustomized
                                            asesores={this.state.asesores.data}
                                            value={this.state.comment}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="center" span={this.state.edit ? 6 : 4}>
                                    <Space size={5} >
                                        {this.state.edit ? <Form.Item >
                                            <Button danger type="primary" onClick={this.cleanData}>
                                                Cancelar
                                            </Button>
                                        </Form.Item>
                                            : null}
                                        <Form.Item >
                                            <Button type="primary" htmlType="submit">
                                                Enviar
                                            </Button>
                                        </Form.Item>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div >
                <FloatingButton
                    className="floating-button-small"
                    icon={<PlusOutlined className="floating-button-small" />}
                    content={<div className="flex-column">
                        <Space direction="vertical">
                            <Button
                                disabled={!this.props.prospecto_id || (this.state.cita._id == null && this.validateDate())}
                                type="primary"
                                icon={<TbBrowserCheck size={"25px"} />}
                                onClick={() => this.setState({ modal_visita_feedback: true })} />
                            <Button
                                disabled={!this.props.prospecto_id}
                                type="primary"
                                icon={<CalendarOutlined size={"25px"} />}
                                onClick={() => this.setState({ modal_visita: true })} />
                        </Space>
                    </div>}
                />
                <ModalVisitas
                    visible={this.state.modal_visita}
                    onClose={() => {
                        this.setState({ modal_visita: false })
                        this.IO_getActualizaciones()
                        this.props.update()
                    }}
                    prospecto_id={this.props.prospecto_id}
                    proyecto={this.props.proyecto}
                />
                <ModalFeedback
                    visible={this.state.modal_visita_feedback}
                    onClose={() => {
                        this.setState({ modal_visita_feedback: false })
                        this.IO_getActualizaciones()
                        this.getCita()
                    }}
                    cita={this.state.cita}
                    prospecto_id={this.props.prospecto_id}
                />
            </>
        )
    }
}

/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 s*/
const Acts = forwardRef((props, ref) => <Actualizaciones
    {...props}
    ref={ref}
    user={useContext(User)}
    socket={useContext(Socket)}
/>)

export default Acts