import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Modal, Menu, Statistic } from 'antd'
import axios from 'axios';
import moment from "moment";

//componentes
import Logged from "../../../Hooks/Logged";
import usePermissions from '../../../Hooks/usePermissions';
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import { DeleteOutlined, EditOutlined, MailOutlined } from "@ant-design/icons";


//modal
import ModalCostos from "./ModalCostos";
import { getResponseError } from '../../Utils';

const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Costos
 * @extends {Component}
 * @description Vista donde se listan todos los Costos
 */
class Costos extends Component {
    static contextType = Logged;



    /**
     *Creates an instance of Costos.
     * @param {*} props
     * @memberof Costos
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            costos_prospectos: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,

            },
            usuario_id: null
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getCostos()
    }

    /**
     *
     * @memberof Costos
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra los usuarios
     */
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search)
            this.getCostos()
    }


    /**
     *
     * @memberof Costos
     * @method getCostos
     * @description Obtiene la informacion de los Costos y actualiza los valores locales de la lista.
     */
    getCostos = async ({
        page = this.state.costos_prospectos.page,
        limit = this.state.costos_prospectos.limit,
        search = this.props.search
    } = this.state.costos_prospectos) => {

        this.setState({ loading: true })

        axios.get('/costos_prospectos', {
            params: { limit, page, search }
        })
        .then(({ data }) => {
            this.setState({ costos_prospectos: data })
        })
        .catch(res => {
            message.error(res.response.data.message)
        })
        .finally(() => this.setState({ loading: false }))
    }



    render() {


        return (
            <>
                <Spin spinning={false}>
                    <Content className='content'>
                        <Card size='small' className='card-header'>
		                    <PageHeader
		                        title="Costos"
		                    />
		                </Card>
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Costos" }}
                            dataSource={this.state.costos_prospectos.data}
                            pagination={{
                                current: this.state.costos_prospectos.page,
                                pageSize: this.state.costos_prospectos.limit,
                                total: this.state.costos_prospectos.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getCostos({ page, limit })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col span={5} className="center">
                                    <Text strong>Costo</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Fecha Inicio</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Fecha Final</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Proyecto</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={5} className="center">
                                                <Statistic
                                                    value={item.costo}
                                                    valueStyle={{ fontSize: 14 }}
                                                    precision={2}
                                                    prefix={"$"}
                                                    suffix="MXN" 
                                                />
                                            </Col>

                                            <Col span={5} className="center">
                                                <Text>{moment(item.fecha_inicio).format("DD/MM/YYYY")}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{moment(item.fecha_final).format("DD/MM/YYYY")}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{item.proyecto_id?.nombre??'N/A'}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Space>
                                                    <Button 
                                                        type="primary" 
                                                        icon={<EditOutlined />}
                                                        onClick={() => this.setState({ modal_visible: true, costo_prospecto_id: item._id })}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este registro?"
                                                        onConfirm={() => axios.delete('/costos_prospecto/'+item._id, {
                                                            }).then((response) => {
                                                                message.success("Se elimino el registro correctamente.")
                                                                this.getCostos()
                                                            })
                                                            .catch((error) => {
                                                                message.error(getResponseError(error.response,"No se pudo eliminar le registro"));
                                                            })
                                                        }
                                                        okText="Sí"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary" danger title="Eliminar" icon={<DeleteOutlined />} />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

                     <ModalCostos
                        visible={this.state.modal_visible}
                        costo_prospecto_id={this.state.costo_prospecto_id}
                        onClose={() => {
                            this.setState({ modal_visible: false, costo_prospecto_id: undefined })
                            this.getCostos()
                        }}
                    />

                </Spin>
            </>
        )
    }
}

export default function (props) {


    let user = React.useContext(Logged)

    return <Costos {...props} />
}