import React, { Component, useContext } from 'react';
import { Card, Layout, PageHeader, Button, Space, Radio, Tag } from "antd";
import TuiCalendar from 'tui-calendar'; /* ES6 */
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from 'axios';
import ModalCitas from './ModalCitas';
import DrawerProspectos from '../Prospectos/Drawer';

import moment from 'moment-timezone'

import "tui-calendar/dist/tui-calendar.css";
import "./Schedule.scss";

import { ProyectoId } from '../../../Hooks/Proyecto';
const { Content, } = Layout;
moment.tz.setDefault("America/Mexico_City")




/**
 *
 *
 * @class Calendario
 * @extends {Component}
 */
class Calendario extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fecha: new Date(),
            duracion: 0,
            modal_visible: false,
            horarios: {},
            alt: false,
            actual: "",
            proyecto_id: this.props.project,
            color: "#dadada"

        }
    }
    calendarRef = React.createRef()


    componentDidMount() {
        this.calendarRef.current = new TuiCalendar(this.calendarRef.current, {
            defaultView: 'month',
            useDetailPopup: false,
            taskView: false,
            calendars: [
                {
                    id: "horarios",
                    name: "horarios",
                }
            ],
            template: {
            },
            month: {
                daynames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
                startDayOfWeek: 0,
            },
        });

        this.calendarRef.current.clear();
        this.calendarRef.current.render();

        this.calendarRef.current.on("beforeCreateSchedule", (event) => {
            console.log('beforeCreateSchedule', event)
            let duracion = moment(event.end._date).diff(moment(event.start._date), "hours", true)

            this.setState({
                fecha: event.start._date,
                modal_visible: true,
                duracion: duracion
            });
        });
        this.calendarRef.current.on("beforeUpdateSchedule", ({ changes, schedule, end, start }) => {
            console.log('beforeUpdateSchedule', changes, schedule)

            this.calendarRef.current.updateSchedule(schedule.id, "horarios", {
                ...changes
            })


            console.log('changes',schedule)
            axios.put('/citas/update', {
                id: schedule.id,
                dia: moment(changes.start ? changes.start._date : schedule.start._date).date(),
                mes: moment(changes.start ? changes.start._date : schedule.start._date).month(),
                year: moment(changes.start ? changes.start._date : schedule.start._date).year(),
                hour: moment(changes.start ? changes.start._date : schedule.start._date).hours(),
                minute: moment(changes.start ? changes.start._date : schedule.start._date).minutes(),
                duracion: moment(changes.end ? changes.end._date : schedule.start._date).diff(moment(changes.start ? changes.start._date : schedule.start._date), "hours", true),
                arrastrada: true
            })
                .then((response) => {
                    console.log(response);
                    this.getCitas()
                }).catch((error) => {
                    console.log(error);
                    this.getCitas()
                })
        })


        this.calendarRef.current.on("clickSchedule", ({ schedule }) => {
            const prospecto_id = schedule.id.split('-')[1]
            console.log("clickSchedule", schedule)
            this.setState({ drawer_visible: true, prospecto_id });

        });
       

        this.setState({
            start: this.calendarRef.current.getDateRangeStart().toDate(),
            end: this.calendarRef.current.getDateRangeEnd().toDate(),
            actual: moment(this.calendarRef.current?.getDate()._date).format("MMMM")
        }, () => this.getCitas())





    }

    componentDidUpdate(prevProps) {


        if (prevProps.project !== this.props.project)
            this.setState({
                start: this.calendarRef.current.getDateRangeStart().toDate(),
                end: this.calendarRef.current.getDateRangeEnd().toDate(),
                proyecto_id: this.props.project,
            }, () => { this.getCitas() })        

    }

  

    renderColor = (cita) => {

        if(cita.asistio === true) return "#00FF00"
        if(cita.asistio === false) return "#FF0000"
        if(!cita.feedback) return "#FFF000"

        return this.state.color
    }

    getCitas = ({
        proyecto_id = this.state.proyecto_id,
        start = this.state.start,
        end = this.state.end
    } = this.state) => {
        this.setState({ proyecto_id, start, end })
        axios.post('/citas/calendario', {
            proyecto_id,
            start,
            end
        })
            .then(({ data }) => {

                console.log('citas',data)
                let schedules = []
                let stateHorarios = []
                for (let horarios of data?.citas) {


                    let prospecto = horarios.prospecto_id;
                    let proyecto = horarios.proyecto_id;

                    let start = moment(horarios.fecha)
                    let end = moment(horarios.fecha).add(horarios.duracion, 'hours')
                    let id = `${horarios._id}`
                    let title = `${moment(start).format("hh:mm a")} - ${moment(end).format("hh:mm a")} \n ${prospecto?.nombre ?? ''} \n ${proyecto?.nombre ?? ''} `
                    let event = {
                        id,
                        title,
                        calendarId: "horarios",
                        category: "time",
                        isVisible: true,
                        isAllDay: false,
                        start: start.toDate(),
                        end: end.toDate(),
                        bgColor: this.renderColor(horarios),
                        dragBgColor: this.renderColor(horarios),
                        borderColor: this.renderColor(horarios)
                    }
                    schedules.push(event)
                    stateHorarios[id] = {
                        ...event,
                        start,
                        end
                    }

                }
                this.calendarRef.current.clear()
                this.calendarRef.current.createSchedules(schedules)

                this.setState({ horarios: stateHorarios })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }




    nextMonth = () => {
        this.calendarRef.current.next()
        this.setState({ actual: moment(this.calendarRef.current?.getDate()._date).format("MMMM") })
        this.setState({
            start: this.calendarRef.current.getDateRangeStart().toDate(),
            end: this.calendarRef.current.getDateRangeEnd().toDate()
        }, () => this.getCitas())

    }

    prevMonth = () => {
        this.calendarRef.current.prev()
        this.setState({ actual: moment(this.calendarRef.current?.getDate()._date).format("MMMM") })
        this.setState({
            start: this.calendarRef.current.getDateRangeStart().toDate(),
            end: this.calendarRef.current.getDateRangeEnd().toDate()
        }, () => this.getCitas())
    }

    changeView = e => {
        this.calendarRef.current.changeView(e.target.value)
    }

    render() {

        return (
            <Content className=" hm-calendar " >
                <Card size='small'>
                    <PageHeader className="site-page-header hm-page-header cnt-page-header"
                        title={<span className="ant-page-header-heading-title">Citas Disponibles</span>}
                    />
                </Card>
                <Card className="hm-card">
                    <Space style={{ width: "100%", justifyContent: "space-between", marginBottom: "2em" }} >
                        <Space>
                            <Button.Group>
                                <Button onClick={this.prevMonth} icon={<LeftOutlined style={{ color: "white" }} />}></Button>
                                <Button onClick={this.nextMonth} icon={<RightOutlined style={{ color: "white" }} />}></Button>
                            </Button.Group>
                        </Space>
                        <Tag style={{ textTransform: "capitalize" }}>{this.state.actual}</Tag>
                        <Space>
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                onChange={this.changeView}
                                options={[
                                    { label: "Mes", value: "month" },
                                    { label: "Semana", value: 'week' },
                                    { label: "Dia", value: "day" },

                                ]}
                            />
                        </Space>
                    </Space>
                    <div ref={this.calendarRef} />
                </Card>
                <ModalCitas
                    visible={this.state.modal_visible}
                    onCancel={() => {
                        this.setState({ modal_visible: false,  fecha: undefined, duracion: undefined });
                        this.getCitas()
                    }}
                    proyecto={this.state.proyecto_id}
                    fecha={this.state.fecha}
                    duracion={this.state.duracion} />
                <DrawerProspectos
                    onClose={() => {
                        this.setState({
                            prospecto_id: null,
                            drawer_visible: false,
                        })
                    }}
                    onSelectProspecto={(prospecto_id) => {}}
                    onCreateProspecto={(p) => {}}
                    onUpdateProspecto={(p) => {}}
                    visible={this.state.drawer_visible}
                    prospecto_id={this.state.prospecto_id}
                />
            </Content>
        )
    }
}

export default function Calendar() {
    let proyecto_id = useContext(ProyectoId)
    return <Calendario project={proyecto_id} />

}