import React, { Component } from "react";
import { Select, Row, Col, Modal, Spin, Form, Input, Button, message, Rate, Radio, DatePicker, Space } from 'antd';

import 'react-multi-carousel/lib/styles.css';
import axios from "axios"
import Avatar from "../../Widgets/Avatar/Avatar";
import { useContext } from "react";
import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import SelectProyecto from "../../Widgets/Inputs/SelectProyecto";
import SelectProspectos from "../../Widgets/Inputs/SelectProspecto";
import SelectAsesor from "../../Widgets/Inputs/SelectAsesor";
import moment from 'moment';
import {
    SmileOutlined, MehOutlined, FrownOutlined,
    CheckOutlined,
    CloseOutlined
} from "@ant-design/icons"
moment.tz.setDefault("America/Mexico_City")

const { Option } = Select;
const { TextArea } = Input;

const customIcons = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />,
};
/**
 *
 *
 * @export
 * @class CitasModal
 * @extends {Component}
 */
class CitasModal extends Component {



    constructor(props) {
        super(props)
        this.state = {

            fecha: moment(this.props.fecha),
            proyectos: {
                data: [],
                page: 1,
                total: 0,
                search: undefined,
                limit: 20,
                loading: false
            },
            prospectos: {
                data: [],
                page: 1,
                total: 0,
                search: undefined,
                limit: 50,
                loading: false
            },
            asesores: {
                data: [],
                page: 1,
                total: 0,
                search: undefined,
                limit: 50,
                loading: false
            },
            loading: false,

            estatus_selected: 0,
            visito: false,
            loadingProyectos: false,
        }

    }

    formModal = React.createRef()

    /**
     *
     * @methodOf CitasModal
     *
     * @function componentDidMount
     * @description Manda a llamar a los asesores, estatus y el prospecto si hay un ObjectId
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.formModal.current.setFieldsValue({
            fecha: moment(this.state.fecha),
            duracion: Math.round(this.props.duracion * 2) / 2,
            hora: moment(this.state.fecha).format('HH:mm'),
        })


        if (this.props.proyecto) {
            this.getProyecto()
            this.setState({ proyecto_id: this.props.proyecto })
        }
    }

    getProyecto = (_id = this.props.proyecto) => {
        axios.get(`/proyectos/${this.props.proyecto}`)
            .then(({ data }) => {
                this.formModal.current.setFieldsValue({
                    proyecto_id: {
                        label: data.nombre,
                        value: data._id
                    }
                })
            })
            .catch((error) => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al obtener los prospectos");
            })
    }


    /**
    *
    * @methodOf CitasModal
    *
    * @function addCita
    * @description Añade una cita
    * */
    addCita = (values) => {
        this.setState({ loading: true })
        axios.post('/citas/add', {
            fecha: values.fecha,
            descripcion: values.descripcion,
            duracion: values.duracion,
            hora: values.hora,
            proyecto_id: values.proyecto_id,
            estatus: values.estatus,
            prospecto_id: values.prospecto_id,
            asesor_id: values.asesor_id,
            ...values
        })
            .then(({ data }) => {
                console.log('se creo cita', data)
                message.success("Cita Guardada")
                this.props.onCancel()
            })
            .catch(error => {
                console.log('error', error)
                message.error(error?.response?.data?.message ?? "No es posible las guardar la cita.")
            })
            .finally(() => this.setState({ loading: false }))

    }


    selectAsesor = (value) => {
        console.log('selectAsesor', value)

    }
    onChangeVisito = (value) => {
        console.log('visito', value)
        this.setState({ visito: value })
    }

    /**
     * @memberof ProspectoModal
     * @method onValuesChange
     * 
     * @description Guardamos los formualrios
     * 
     */
    saveFormulario = (values) => {

        if (values.fecha) {
            let hora = values.hora.split(":").map(h => Number(h)) || [0, 0]
            values.fecha = moment(values.fecha).set({ hours: hora[0], minutes: hora[1] }).toDate()
        }
        if (values.proyecto_id) values.proyecto_id = values.proyecto_id.value
        if (values.asesor_id) values.asesor_id = values.asesor_id.value
        if (values.prospecto_id) values.prospecto_id = values.prospecto_id.value


        this.addCita(values)

    }

    render() {
        return (
            <Row  >
                <Col span={24} >
                    <div>
                        <p className="text-center"> Nueva Cita</p>
                    </div>
                    <Spin spinning={this.state.loading}>
                        <Form
                            className="pd-1"
                            layout="vertical"
                            onFinish={this.saveFormulario}
                            ref={this.formModal}
                            initialValues={{
                                fecha: moment(this.state.fecha),
                                duracion: Math.round(this.props.duracion * 2) / 2,
                                hora: moment(this.state.fecha).format('HH:mm'),
                            }}

                        >
                            <Row justify={"start"} gutter={[8, 20]}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Fecha"
                                        name="fecha"
                                    >
                                        <DatePicker className="width-100" format={'DD-MM-YYYY'} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Hora de inicio"
                                        name="hora"
                                        rules={[{
                                            required: true,
                                            message: 'Por favor seleccione la hora de la cita'
                                        }]}
                                    >
                                        <Select>
                                            <Option value="00:00">00:00</Option>
                                            <Option value="00:30">00:30</Option>
                                            <Option value="01:00">01:00</Option>
                                            <Option value="01:30">01:30</Option>
                                            <Option value="02:00">02:00</Option>
                                            <Option value="02:30">02:30</Option>
                                            <Option value="03:00">03:00</Option>
                                            <Option value="03:30">03:30</Option>
                                            <Option value="04:00">04:00</Option>
                                            <Option value="04:30">04:30</Option>
                                            <Option value="05:00">05:00</Option>
                                            <Option value="05:30">05:30</Option>
                                            <Option value="06:00">06:00</Option>
                                            <Option value="06:30">06:30</Option>
                                            <Option value="07:00">07:00</Option>
                                            <Option value="07:30">07:30</Option>
                                            <Option value="08:00">08:00</Option>
                                            <Option value="08:30">08:30</Option>
                                            <Option value="09:00">09:00</Option>
                                            <Option value="09:30">09:30</Option>
                                            <Option value="10:00">10:00</Option>
                                            <Option value="10:30">10:30</Option>
                                            <Option value="11:00">11:00</Option>
                                            <Option value="11:30">11:30</Option>
                                            <Option value="12:00">12:00</Option>
                                            <Option value="12:30">12:30</Option>
                                            <Option value="13:00">13:00</Option>
                                            <Option value="13:30">13:30</Option>
                                            <Option value="14:00">14:00</Option>
                                            <Option value="14:30">14:30</Option>
                                            <Option value="15:00">15:00</Option>
                                            <Option value="15:30">15:30</Option>
                                            <Option value="16:00">16:00</Option>
                                            <Option value="16:30">16:30</Option>
                                            <Option value="17:00">17:00</Option>
                                            <Option value="17:30">17:30</Option>
                                            <Option value="18:00">18:00</Option>
                                            <Option value="18:30">18:30</Option>
                                            <Option value="19:00">19:00</Option>
                                            <Option value="19:30">19:30</Option>
                                            <Option value="20:00">20:00</Option>
                                            <Option value="20:30">20:30</Option>
                                            <Option value="21:00">21:00</Option>
                                            <Option value="21:30">21:30</Option>
                                            <Option value="22:00">22:00</Option>
                                            <Option value="22:30">22:30</Option>
                                            <Option value="23:00">23:00</Option>
                                            <Option value="23:30">23:30</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Duración"
                                        name="duracion"
                                    >
                                        <Select>
                                            <Option value={0.5}>30 minutos</Option>
                                            <Option value={1}>1 hora</Option>
                                            <Option value={1.5}>1 hora 30 minutos</Option>
                                            <Option value={2}>2 horas</Option>
                                            <Option value={2.5}>2 horas 30 minutos</Option>
                                            <Option value={3}>3 horas</Option>
                                            <Option value={3.5}>3 horas 30 minutos</Option>
                                            <Option value={4}>4 horas</Option>
                                            <Option value={4.5}>4 horas 30 minutos</Option>
                                            <Option value={5}>5 horas</Option>
                                            <Option value={5.5}>5 horas 30 minutos</Option>
                                            <Option value={6}>6 horas</Option>
                                            <Option value={6.5}>6 horas 30 minutos</Option>
                                            <Option value={7}>7 horas</Option>
                                            <Option value={7.5}>7 horas 30 minutos</Option>
                                            <Option value={8}>8 horas</Option>
                                            <Option value={8.5}>8 horas 30 minutos</Option>
                                            <Option value={9}>9 horas</Option>
                                            <Option value={9.5}>9 horas 30 minutos</Option>
                                            <Option value={10}>10 horas</Option>
                                            <Option value={10.5}>10 horas 30 minutos</Option>
                                            <Option value={11}>11 horas</Option>
                                            <Option value={11.5}>11 horas 30 minutos</Option>
                                            <Option value={12}>12 horas</Option>
                                            <Option value={12.5}>12 horas 30 minutos</Option>
                                            <Option value={13}>13 horas</Option>
                                            <Option value={13.5}>13 horas 30 minutos</Option>
                                            <Option value={14}>14 horas</Option>
                                            <Option value={14.5}>14 horas 30 minutos</Option>
                                            <Option value={15}>15 horas</Option>
                                            <Option value={15.5}>15 horas 30 minutos</Option>
                                            <Option value={16}>16 horas</Option>
                                            <Option value={16.5}>16 horas 30 minutos</Option>
                                            <Option value={17}>17 horas</Option>
                                            <Option value={17.5}>17 horas 30 minutos</Option>
                                            <Option value={18}>18 horas</Option>
                                            <Option value={18.5}>18 horas 30 minutos</Option>
                                            <Option value={19}>19 horas</Option>
                                            <Option value={19.5}>19 horas 30 minutos</Option>
                                            <Option value={20}>20 horas</Option>
                                            <Option value={20.5}>20 horas 30 minutos</Option>
                                            <Option value={21}>21 horas</Option>
                                            <Option value={21.5}>21 horas 30 minutos</Option>
                                            <Option value={22}>22 horas</Option>
                                            <Option value={22.5}>22 horas 30 minutos</Option>
                                            <Option value={23}>23 horas</Option>
                                            <Option value={23.5}>23 horas 30 minutos</Option>
                                            <Option value={24}>24 horas</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Estatus"
                                        name="estatus"
                                        rules={[{
                                            required: true,
                                            message: 'Por favor seleccione el estatus'
                                        }]}
                                    >
                                        <Select onChange={(e) => this.setState({ estatus_selected: e })}>
                                            <Option value={0}>Cancelada</Option>
                                            <Option value={1}>Creada</Option>
                                            <Option value={2}>Confirmada</Option>
                                            <Option value={3}>Concluida</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Proyecto"
                                        name="proyecto_id"
                                        hidden={this.props.proyecto ? true : false}
                                    >
                                        <SelectProyecto
                                            onSelect={({ value }) => {
                                                this.setState({ proyecto_id: value }, () => {
                                                    this.formModal.current.setFieldsValue({ prospecto_id: null })
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Prospecto"
                                        name="prospecto_id"
                                        rules={[{
                                            required: true,
                                            message: 'Por favor seleccione el prospecto'
                                        }]}

                                    >
                                        <SelectProspectos
                                            params={{
                                                proyecto_id: this.props.proyecto
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Asesor"
                                        name="asesor_id"
                                        rules={[{
                                            required: true,
                                            message: 'Por favor seleccione el asesor'
                                        }]}
                                    >
                                        <SelectAsesor
                                            getOption={(item) => ({
                                                value: item._id,
                                                label: <Space>
                                                    <Avatar
                                                        style={{ marginRight: 10, backgroundColor: item?.color }}
                                                        color={item?.color}
                                                        image={item?.avatar ? axios.defaults.baseURL + "/usuario/" + item?._id + "?avatar=true&Authorization=" + sessionStorage.getItem("token") : null}
                                                        name={item?.nombre}
                                                    />
                                                    {item.nombre}
                                                </Space>
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Descripción"
                                        name="descripcion"
                                    >
                                        <Input.TextArea placeholder="Descripcion" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {this.state.estatus_selected == 3 ?
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label="¿Asistió el prospecto?"
                                            name="asistio"
                                            className="inline-form-item"
                                            rules={[{
                                                required: true,
                                                message: 'Por favor seleccione una opción'
                                            }]}
                                        >
                                            <Radio.Group size="large" className="radio-icons " onChange={(e) => this.onChangeVisito(e.target.value)}>
                                                <Radio.Button value={true}><CheckOutlined style={{ color: '#00FF00' }} /></Radio.Button>
                                                <Radio.Button value={false}><CloseOutlined style={{ color: '#FF0000' }} /></Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="FeedBack"
                                            name="feedback"
                                        >
                                            <TextArea rows={4} className="width-100" />
                                        </Form.Item>
                                    </Col>
                                    {this.state.visito ?
                                        <Form.Item
                                            label="¿Cómo calificarías la visita?"
                                            name="valoracion"
                                            rules={[{
                                                required: true,
                                                message: 'Por favor asigne una calificación'
                                            }]}
                                        >
                                            <Rate className="custom-rate"
                                                character={({ index }) => customIcons[index + 1]}
                                            />
                                        </Form.Item>
                                        : null}
                                </Row> : null}
                            <Row justify="center"  >
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="btn-mdl-save" disabled={(!this.props.crearCitas && !this.props.cita) || (!this.props.editarCitas && this.props.cita)}>
                                        GUARDAR
                                    </Button>
                                </Form.Item>

                            </Row>
                        </Form>
                    </Spin>
                </Col>
            </Row>
        );
    }

};

export default function (props) {

    const user = useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearCitas: ['citas', 'create'],
        editarCitas: ['citas', 'edit'],
        eliminarCitas: ['citas', 'delete']
    })

    return <Modal
        open={props.visible}
        onCancel={props.onCancel}
        closable={true}
        footer={false}
        destroyOnClose={true}
        width={720}
    >
        <CitasModal {...props} {...permisos} />
    </Modal >
}


