import React, { createRef, useContext } from 'react';
import { Link, useSearchParams, useParams } from "react-router-dom"
import Column from './Board/column';
import { Layout, Row, Col, PageHeader, Button, DatePicker, Card, Spin, Form, Input, Empty, message, Space, Typography } from 'antd'
import { GoPlus } from 'react-icons/go';
import { SyncOutlined } from '@ant-design/icons'
import { HiOutlineRectangleGroup } from "react-icons/hi2";

import { DragDropContext } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import DrawerProspecto from "./Drawer"
import { ProyectoId, SetProyecto } from "../../../Hooks/Proyecto";
import Socket from '../../../Hooks/Socket';
import User from '../../../Hooks/Logged';
import usePermissions from '../../../Hooks/usePermissions';


const { Content, } = Layout;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Text } = Typography;

class Board extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            proyecto_id: null,
            updating: true,

            //Filtros
            estatus_id: undefined,
            asesor_id: undefined,
            fuente: undefined,
            fechas: undefined,
            probabilidad: false,


            dir_estatuses: {},
            page: 1,
            total: 0,
            limit: 10,
            height: 0
        }
    }

    contentRef = createRef()

    componentDidMount() {
        this.props.socket.on("/admin/prospectos/board", this.IO_setProspectos)
        this.props.socket.on("/admin/prospectos/prioridad", this.IO_onPrioridad)
        this.props.socket.on("/admin/prospectos/error", this.IO_Error)

        if (this.props.projecto_id != null || this.props.projecto_id != undefined) {
            this.setState({
                proyecto_id: this.props.projecto_id
            })
            this.getEstatus()
        }

       
        window.addEventListener('scroll', this.handleScroll, true)

    }


    componentDidUpdate(prevProps) {
        if (this.props.projecto_id != null && this.props.projecto_id && this.props.projecto_id !== prevProps.projecto_id) {
            this.resetData()
        }
        if (this.props.projecto_id == null && this.state.proyecto_id) {
            this.setState(state => {
                state.estatus = []
                state.page = 1
                state.proyecto_id = null
                return state;
            })
        }
    }

    componentWillUnmount() {
        this.props.socket.emit("/admin/prospectos/off")

        window.removeEventListener("scroll", this.handleScroll)
        this.props.socket.removeEventListener("/admin/prospectos")
        this.props.socket.removeEventListener("/admin/prospectos/prioridad")
        this.props.socket.removeEventListener("/admin/prospectos/board")
    }

    resetData = (p = null) => {
        this.setState(state => {
            state.estatus = []
            state.dir_estatuses = {}
            state.page = 1
            state.update = false
            state.proyecto_id = this.props.projecto_id
            state.prospecto = p
            return state;
        }, () => this.getEstatus())
    }

    IO_onPrioridad = data => {
        console.log('onPrioridad', data);

    }

    /**
     * 
     * @param {*} data 
     */
    IO_setProspectos = data => {
        let estatus = data.data;
        let dir_estatuses = this.state.dir_estatuses

        if (data.page == 1) {
            dir_estatuses = {}
            data.data.forEach(e => {
                dir_estatuses[e._id] = {
                    _id: e._id,
                    nombre: e.nombre,
                    color: e.color,
                    prospectos: e.prospectos
                }
            })
        }
        else {
            for (let index = 0; index < estatus.length; index++) {
                const element = estatus[index];
                if (dir_estatuses[element._id] == null) {
                    dir_estatuses[element._id] = {
                        _id: element._id,
                        nombre: element.nombre,
                        color: element.color,
                        prospectos: element.prospectos
                    }
                }
                else {
                    dir_estatuses[element._id].prospectos = [...dir_estatuses[element._id].prospectos, ...element.prospectos]
                }
            }
        }
        let array_estatus = estatus.map(function (estatus) {
            estatus.prospectos = null
            return estatus
        });

        this.setState({
            page: data.page,
            pages: data.pages,
            estatus: [...array_estatus],
            dir_estatuses,
            updating: false,
            loading: false
        })


    }

    /**
     *
     *
     * @memberof ProspectoBoard
     * @method IO_Error
     * @description Muestra los errores que pudieran ocurrir en el socket 
     */
    IO_Error = (response) => {
        message.error(response.message)
    }

    /**
     *
     * @methodOf Mensajes
     * @function getEstatus
     * 
     * @description Obtenemos los estatus.
     * */
    getEstatus = ({
        asesor_id = this.state.asesor_id,
        estatus = this.state.estatus_id,
        fuente = this.state.fuente,
        fechas = this.state.fechas,
        search = this.state.search,
        probabilidad = this.state.probabilidad,
        page = this.state.page,
        limit = this.state.limit,
    } = {}) => {
        this.setState({ loading: true });

        this.props.socket.emit("/admin/prospectos/board", {
            proyecto_id: this.props.projecto_id,
            search,
            estatus,
            fuente,
            fechas,
            probabilidad,
            page,
            limit,
            usuario_id: this.props.user?._id
        })
    }

    /**
     *
     *
     * @param {object[]} lista - array de prospectos
     * @memberof Board
     * @description Ordena el listado/columna de prospectos usando una variacion del QuickSort
     */
    sort = (lista) =>{
        if(lista.length <= 1) return lista

        const pivote = lista[0]
        const menores = []
        const mayores = []
        const iguales = []

        for(const item of lista){
            if(item.probabilidad > pivote.probabilidad){ 
                mayores.push(item)
            }else if(item.probabilidad < pivote.probabilidad) {
                menores.push(item)
            } else {
                if(item.nombre > pivote.nombre){
                    menores.push(item)
                }else if(item.nombre < pivote.nombre){
                    mayores.push(item)
                }else{
                    iguales.push(item)
                }
            }
        }

        return [
            ...this.sort(mayores),
            ...iguales,
            ...this.sort(menores)
        ]
    }

    /**
     *
     *
     * @memberof Board
     * @method onDragEnd
     * @description Al soltar el card a actualizar, se envia la peticion al webservice para actualizar el estatus
     */
    onDragEnd = result => {

        if (!this.props.editarProspectos) {
            message.info("No tienes los permisos para realizar esta acción")
            return
        }

        console.log('result', result);
        let prospecto = result.draggableId;

        const { destination, source, draggableId } = result;

        if (!destination) return;
        if (source.droppableId === destination.droppableId) return;


        this.props.socket.emit("/admin/prospectos/prioridad", {
            prospecto_id: prospecto,
            prioridad: destination.index,
            estatus: destination.droppableId
        })

        let origen = Array.from(this.state.dir_estatuses[source.droppableId].prospectos)
        let destino = Array.from(this.state.dir_estatuses[destination.droppableId].prospectos)

        // Encuentra el prospecto por su id y eliminar de su listado
        let index = origen.findIndex(p => p._id === draggableId)
        let [item] = origen.splice(index, 1)

        // Agregar el registro al listado destino y ordenarlo por probabilidad y nombre
        destino.push(item)
        destino = this.sort(destino)

        this.setState(state => {
            state.dir_estatuses[source.droppableId].prospectos = origen
            state.dir_estatuses[destination.droppableId].prospectos = destino
            return state
        })

    }

    /**
     *
     *
     * @memberof Board
     * @method updateProspectos
     * @description  Actualiza los prospectos de los estatus sin mandar a llamar de nuevo el servicio, esto para mejor rendimiento
     * solo se ejecuta si se completo el update del estatus del prospecto
     */
    updateProspectos = (result, order = false) => {


        const { destination, source, draggableId } = result;

        let dir_estatuses = this.state.dir_estatuses
        let estatus_old = source.droppableId;
        let prospecto = draggableId;
        let estatus_new = destination.droppableId;


        if (!order) {

            let index_prospecto = dir_estatuses[estatus_old].prospectos.findIndex(p => p._id == prospecto)

            let element = dir_estatuses[estatus_old].prospectos[index_prospecto];
            dir_estatuses[estatus_new].prospectos.push(element)


            let i = dir_estatuses[estatus_old].prospectos.findIndex(p => p._id == prospecto)
            dir_estatuses[estatus_old].prospectos.splice(i, 1)

        }
        else {

            //prospecto movido
            let index_prospecto = dir_estatuses[estatus_old].prospectos.findIndex(p => p._id == prospecto)
            //si el indice destino es mayor al actual se movio hacia  abajo
            if (destination.index > source.index)
                console.log('se movio hacia arriba')
            else
                console.log('se movio hacia abajo')


        }

        this.setState(state => {
            state.loading = false
            state.dir_estatuses = dir_estatuses
            return state;
        })
    }
    /**
        *
        *
        * @memberof ProspectosBoard
        * @method updateProspectos
        * @description Actualiza los datos del prospecto que edito al abrir el modal
        * @param {*} prospecto Objeto del prospecto con sus datos
        * @param {Number} estatus_index indice del estatus donde el prospecto se selecciono para ser editado 
        *                               solo aplica para listado
        * @param {Boolean} is_otroEstatus Verifica si se cambio el estatus del prospecto
        */
    updateProspecto = (prospecto, index_estatus = null, old, is_otroEstatus) => {


        if (is_otroEstatus) {
            this.move(prospecto, old)
        }
        else {
            let arr_pros = this.state.dir_estatuses[prospecto.estatus_id._id].prospectos;
            let index = arr_pros.findIndex(p => p._id == prospecto._id)
            if (arr_pros[index] != -1) {
                arr_pros[index] = prospecto;

                this.setState(state => {
                    state.dir_estatuses[prospecto.estatus_id._id].prospectos = arr_pros;
                    return state
                })
            }
            else
                message.error('no se actualizo prospecto')

        }
    }

    /**
     * 
     * @param {*} prospecto 
     * @param {*} estatus_old 
     * @returns 
     */
    move = (prospecto, estatus_old) => {

        let estatuses = this.state.dir_estatuses;

        let index_delete = estatuses[estatus_old].prospectos.findIndex(p => p._id == prospecto._id)
        estatuses[estatus_old].prospectos.splice(index_delete, 1);

        estatuses[prospecto.estatus_id._id].prospectos.push(prospecto)

        this.setState(state => {
            state.loading = false
            state.dir_estatuses = estatuses
            return state;
        })
    }
    /**
     *
     * @methodOf ProspectosEstatus
     * @function handleScroll
     * 
     * @description Se ejecuta cuando se scrollea el la vista, cargamos mas prospectos de otros estatus hasta llegar al final
     * 
     * */
    handleScroll = (event) => {
        let height = this.contentRef?.current?.clientHeight
        let gap = 20
        let {scrollY, innerHeight} = window

        // console.log(height - scrollY, innerHeight + gap, (height - scrollY) < (innerHeight + gap), height > innerHeight)

        if ( height > innerHeight && (height - scrollY) < (innerHeight + gap) && !this.state.updating) {
            this.setState(prevState => ({ page: parseInt(prevState.page) + 1 }), () => this.getEstatus())
        }
    }

    /**
    *
    *
    * @memberof ProspectoBoard
    * @description Asigna los filtros para cargar los datos
    */
    filter = (changeValue, allValues) => {
        this.setState(allValues, () => this.getEstatus({ page: 1 }));
    }
    /**
    *
    *
    * @memberof ProspectoBoard
    * @description Abre el modal de prospectos
    * @param status Estatus seleccionado de la lista
    * @param prospecto ObjectId del prospecto seleccionado
    */
    drawer_visible = (prospecto, proyecto) => {
        this.setState({ prospecto, drawer_visible: true, prospecto_proyecto: proyecto })
    }

    /**
     *
     *
     * @memberof Prospectos
     * @description Cierra el modal de prospectos
     */
    onCancel = () => {
        this.setState({
            prospecto: null,
            prospecto_proyecto: null,
            drawer_visible: false,
        })
    };



    render() {
        let dir = this.state.dir_estatuses;

        return (
            <>
                <Content className="hm-layout-content content" ref={this.contentRef}>
                    <Card className='width-100'>
                        <PageHeader className="site-page-header hm-page-header cnt-page-header"
                            title={
                                <Form
                                    className="form-filters"
                                    onValuesChange={this.filter}>
                                    <Row className="width-100" justify="space-between">
                                        <Col span={3} xxl={{ span: 3, order: 1 }} xl={{ span: 4, order: 1 }} lg={{ span: 17, order: 1 }} md={{ span: 14, order: 1 }} sm={{ span: 14, order: 1 }} xs={{ span: 14, order: 1 }}  >
                                            <span className="ant-page-header-heading-title" title="">
                                                {this.props?.params?.asesor_id ? "Asesor" : "Prospectos Board"}
                                            </span>
                                            {this.props.params?.asesor_id ?
                                                <p className="ant-page-header-heading-title" style={{ fontSize: 14, fontWeight: 'lighter' }}>{`${this.state.asesor?.nombre}`}</p> : null}
                                        </Col>
                                        <Col span={4} xxl={{ span: 4, order: 2 }} xl={{ span: 5, order: 2 }} lg={{ span: 7, order: 2 }} md={{ span: 10, order: 2 }} sm={{ span: 10, order: 2 }} xs={{ span: 10, order: 2 }} >
                                            {(this.state.proyecto_id !== null) ? <Button ghost type='primary' disabled={!this.props.crearProspectos} icon={<GoPlus />} onClick={() => this.setState({ drawer_visible: true, prospecto: null })}>Nuevo Prospecto</Button> : null}
                                        </Col>
                                        <Col span={6} xxl={{ span: 6, order: 6 }} xl={{ span: 5, order: 6 }} lg={{ span: 9, order: 6 }} md={{ span: 12, order: 6 }} sm={{ span: 12, order: 6 }} xs={{ span: 16, order: 6 }} >
                                            <Form.Item noStyle name="fechas">
                                                <RangePicker
                                                    placeholder={['Inicio', 'Fin']} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="pt-1">
                                        <Col span={12} xxl={{ span: 12, order: 8 }} xl={{ span: 12, order: 8 }} lg={{ span: 12, order: 8 }} md={{ span: 12, order: 8 }} sm={{ span: 24, order: 8 }} xs={{ span: 24, order: 8 }} >
                                            <Form.Item noStyle name="search">
                                                <Search
                                                    placeholder="Buscar Prospectos por Nombre, Teléfono, Email"
                                                    allowClear
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        />
                    </Card>

                    {this.state.proyecto_id ? <>
                        <Row gutter={[20, 20]} style={{ overflowX: 'auto', marginBottom: 200 }} className="width-100 pd-1" align="start" wrap={false} justify="start">
                            <DragDropContext sensors={[
                                api => this.apiRef = api
                            ]} onDragEnd={this.onDragEnd} >
                                {
                                    Object.keys(dir).map((key) => <Column
                                        key={key}
                                        column={dir[key]}
                                        tasks={dir[key].prospectos}
                                        probabilidad={this.state.probabilidad}
                                        proyecto={this.state.proyecto_id}
                                        drawer_visible={this.drawer_visible}
                                    />
                                    )
                                }

                            </DragDropContext>
                            <Row className=" width-100" align="middle" justify={'center'}>
                                {<Spin
                                    indicator={<SyncOutlined spin style={{ display: "absolute", botom: 10, margin: "-10px auto", fontSize: 30, color: '#F506CF' }} />}
                                    spinning={this.state.loading}
                                    size={"large"}
                                    style={{ display: "absolute", botom: 10, margin: "auto" }}
                                />}

                            </Row>
                        </Row>
                    </>
                        :
                        <>
                            <Row className='width-100 pt-1'>
                                <Card className="empty-board" bodyStyle={{ height: 'inherit' }}>
                                    <Empty
                                        image={<HiOutlineRectangleGroup />}
                                        imageStyle={{
                                            fontSize: 100,
                                            color: '#942AA0'
                                        }}
                                        style={{ position: 'relative', top: '30%' }}
                                        description={
                                            <span>
                                                Selecciona un proyecto para visualizar los prospectos
                                            </span>
                                        }
                                    >
                                    </Empty>
                                </Card>
                            </Row>
                        </>}
                    <DrawerProspecto
                        onClose={this.onCancel}
                        visible={this.state.drawer_visible}
                        prospecto_id={this.state.prospecto?._id}
                        prospecto={this.state.prospecto}
                        onCreateProspecto={(p) => this.resetData(p)}
                        onUpdateProspecto={(p) => this.resetData(p)}
                    />
                </Content >
            </>
        )
    }
}

export default function Vista(props) {

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const proyecto = useContext(ProyectoId)
    const user = useContext(User)
    let socket = useContext(Socket)

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarProspectos: ['prospectos', 'edit'],
        crearProspectos: ['prospectos', 'create']
    })

    return <Board {...props} {...permisos}
        user={user}
        socket={socket}
        navigate={navigate}
        projecto_id={proyecto}
        params={useParams()}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
    />
}