

import React, { Component, useEffect, useState, useRef, useContext } from "react";
import { Comment, Typography, Popconfirm, Tooltip, Button, Space, Image, Avatar as AntDAvatar, Empty, Tag, Spin } from "antd"
import { reformat, reformatStatus } from '../../../Widgets/LinkUrlGenerate'
import axios from "axios";
import Avatar from "../../../Widgets/Avatar/Avatar";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"

import { User } from "../../../../Hooks/Logged";

import FilesBox from "../Actualizaciones/FilesView";
import { SlEarphonesAlt } from "react-icons/sl";
import { MdSecurity } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";
import { WhatsAppOutlined } from "@ant-design/icons";
const moment = require('moment-timezone');


const { Text } = Typography

/**
 *
 *
 * @param {*} props
 * @returns {Component}
 */
function MessagesList(props) {
    const { data, tipo, className, prospecto_id, } = props;

    /**@isOverflowed  si el contenedor esta desbordado*/
    const [isOverflowed, setIsOverflowed] = useState(false);

    /**@chatContainer referencia al contenedor del chat */
    const chatContainer = useRef(null);

    /**@loading Carga de datos */
    const [loading, setLoading] = useState(false);

    /**@timezone Zona horaria */
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    /**@proyecto Contexto del usuario logeado */
    const usuario = useContext(User)


    /**
     * @function scrollToBottom
     * @description Hace scroll cuando el contenedor recibe un nuevo elemento
     */
    function scrollToBottom() {
        chatContainer.current.scrollTo({
            top: chatContainer.current.scrollHeight,
            behavior: "smooth"
        });
    }
    /** 
    *@function ScrollTop
    *@description Verifica cuando ya se ha hecho scroll hasta arriba del contenedor
    */
    function ScrollTop() {
        chatContainer.current.scrollTo({
            top: chatContainer.current.clientHeight - 12,
            behavior: "smooth"
        });
        setLoading(true)
        props.loadMore()
        setLoading(false)

    }

    /**Asigna a la bandera si el contenedor esta desbordado */
    useEffect(() => {
        setIsOverflowed(chatContainer.current.scrollHeight > chatContainer.current.clientHeight);
    }, []);


    const generateImage = (entrada) => {
        const images = [{
            src: '/img/humanistics.png'
        }, {
            src: '/img/f_logo_RGB-Blue_512.png'
        }, {
            src: '/img/instagram.png'
        }, {
            src: '/img/humanistics.png'
        }, {
            icon: <SlEarphonesAlt />
        }, {
            src: <MdSecurity />,
            style: { backgroundColor: '#f56a00' }
        }, {
            src: <BsPeopleFill />,
            style: { backgroundColor: '#87d068' }
        }, {
            src: <WhatsAppOutlined />,
            style: { backgroundColor: '#00ff00' }
        }]

        let index = 0

        if (entrada.match(/facebook/i)) index = 1
        if (entrada.match(/instagram/i)) index = 2
        if (entrada.match(/whatsapp/i)) index = 7


        return images[index]

    }


    /**
    *@function handleScroll
    *@description Maneja el scroll hacia arriba o abajo 
    *
    */
    function handleScroll() {
        const scrollActual = chatContainer.current.scrollTop;
        // Scroll hacia arriba (hacia la parte superior)
        if (scrollActual <= 0 && !loading) {
            setLoading(true)
            props.loadMore()
            setLoading(false)
        }


    }


    /**Añade el evento del scroll al contenedor*/
    useEffect(() => {
        chatContainer.current?.addEventListener('scroll', handleScroll);
        return () => {
            chatContainer.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (props.push)
            scrollToBottom()
    })

    /**
     * @function printHour
     * @description Muestra la hora en un formato especifico */
    const printHour = (date) => {
        let hora = moment(date)
        try {
            return hora.tz(timezone).format('LLL')
        } catch (e) {
            console.log(e)
        }
    }


    const ReplyComment = (item) => {

        const has_fuente = (!item.usuario && !item.is_comment)

        return <Comment
            key={item?._id}
            className={`width-100 ${className} ${item.automatico ? ' message-auto ' : ' '}`}
            author={!item.automatico ? <span><Text strong className="user-writen">  {item.usuario?.nombre}</Text> escribió</span> : null}
            datetime={
                <Space direction="horizontal">
                    <span className="flex-end">{printHour(item.timestamp ?? item.createdAt)}</span>
                    {item.cita_id ? <span className="flex-end">{<Tag color="purple">Visita</Tag>}</span> : null}
                </Space>
            }
            avatar={
                has_fuente ?
                    <AntDAvatar {...generateImage(item.entrada)} /> :
                    <Avatar
                        name={item?.usuario?.nombre}
                        color={item?.usuario?.color}
                        size={item.automatico ? "small" : "default"}
                        url={
                            item.usuario?.avatar ? {
                                url: axios.defaults.baseURL + '/usuarios/' + item.usuario._id,
                                avatar: item.usuario.avatar.name
                            } : null}
                    />
            }
            content={
                <>
                    <div className={'message-content'}>
                        {
                            (item.entrada !== "" && item.entrada !== undefined) ?
                                (item.entrada.includes("})")) ? <Text > {reformatStatus(item.entrada)}</Text> :
                                    (!item.entrada.includes("]{")) ? <Text > {(item.entrada)}</Text> : <Text > {reformat(item.entrada)}</Text> : null
                        }
                    </div>
                    <FilesBox id={prospecto_id} files={item.files} className="files-message-box" edit={false} preview={true} />
                </>
            }

            actions={
                (!item.automatico) && (item.usuario?._id == usuario._id) ?
                    [
                        <Tooltip key="edit-comment" title="Editar">
                            <Button
                                type="link"
                                size="small"
                                icon={<EditOutlined
                                />}
                                onClick={() => props.updateMessage(item._id)} />
                        </Tooltip>,
                        <Popconfirm
                            placement="bottomLeft"
                            onConfirm={() => props.deleteMessage(item._id)}
                            onCancel={null}
                            okText="Eliminar"
                            cancelText="Cancelar"
                            title="Eliminar mensaje"
                        >
                            <Button danger type="link" size="small" icon={<DeleteOutlined />} />
                        </Popconfirm>
                    ] : []
            }
        >
        </Comment >
    }



    return <div className="lista-messages" ref={chatContainer} >
        {(loading) ? <Spin spinning={loading}>Cargando</Spin> : null}
        {
            data.length > 0 ? data.map(item => {
                const has_fuente = (!item.usuario && !item.is_comment)
                return <Comment
                    key={item?._id}
                    className={`width-100 ${className} ${item.automatico ? ' message-auto ' : ' '}`}
                    author={!item.automatico ? <span><Text strong className="user-writen">  {item.usuario?.nombre}</Text> escribió</span> : null}
                    datetime={
                        <Space direction="horizontal">
                            <span className="flex-end">{printHour(item.timestamp ?? item.createdAt)}</span>
                            {item.cita_id ? <span className="flex-end">{<Tag color="purple">Visita</Tag>}</span> : null}
                        </Space>
                    }
                    avatar={
                        has_fuente ?
                            <AntDAvatar {...generateImage(item.entrada)} /> :
                            <Avatar
                                name={item?.usuario?.nombre}
                                color={item?.usuario?.color}
                                size={item.automatico ? "small" : "default"}
                                url={
                                    item.usuario?.avatar ? {
                                        url: axios.defaults.baseURL + '/usuarios/' + item.usuario._id,
                                        avatar: item.usuario.avatar.name
                                    } : null}
                            />
                    }
                    content={
                        <>
                            <div className={'message-content'}>
                                {
                                    (item.entrada !== "" && item.entrada !== undefined) ?
                                        (item.entrada.includes("})")) ? <Text > {reformatStatus(item.entrada)}</Text> :
                                            (!item.entrada.includes("]{")) ? <Text > {(item.entrada)}</Text> : <Text > {reformat(item.entrada)}</Text> : null
                                }
                            </div>
                            <FilesBox id={prospecto_id} files={item.files} className="files-message-box" edit={false} preview={true} />
                        </>
                    }

                    actions={
                        (!item.automatico) && (item.usuario?._id == usuario._id) ?
                            [
                                <Tooltip key="edit-comment" title="Editar">
                                    <Button
                                        type="link"
                                        size="small"
                                        icon={<EditOutlined
                                        />}
                                        onClick={() => props.updateMessage(item._id)} />
                                </Tooltip>,
                                <Popconfirm
                                    placement="bottomLeft"
                                    onConfirm={() => props.deleteMessage(item._id)}
                                    onCancel={null}
                                    okText="Eliminar"
                                    cancelText="Cancelar"
                                    title="Eliminar mensaje"
                                >
                                    <Button danger type="link" size="small" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            ] : []
                    }
                >
                    {(Array.isArray(item.respuestas) && item.respuestas.length > 0) ?
                        item.respuestas.map(element => <ReplyComment key={element._id} {...element} {...props} />)
                        : null}
                </Comment>
            }
            ) : <Empty />
        }
    </div>
}


export default MessagesList;