import React, { useEffect, useState, } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Layout, Row, Col, PageHeader, Collapse, Spin, Button, Space, DatePicker, Affix, Card, Form, Input, message, Typography, Checkbox, Select } from 'antd'
import { createUseStyles } from 'react-jss';
import { ArrowLeftOutlined, LeftOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import axios from 'axios';

import ListaProspectos from './ListaProspectosEstatus';
import '../../../Styles/Modules/Prospectos/prospectos.scss';
import { GoPlus } from 'react-icons/go';

import { Proyecto, ProyectoId } from '../../../Hooks/Proyecto';
import DrawerProspectos from "../../Admin/Prospectos/Drawer";
import BtnProspectos from '../../Widgets/BtnProspectos/BtnProspectos';
import { useContext } from 'react';
import usePermissions from '../../../Hooks/usePermissions';
import User from '../../../Hooks/Logged';
import { getResponseError } from '../../Utils';

const { Panel } = Collapse;
const { Text } = Typography;
const { Content } = Layout;
const { Search } = Input;
const { RangePicker } = DatePicker


const useStyles = createUseStyles({
    buttonTitle: {
        textAlign: "start",
        '&, &:focus, &:hover': {
            minWidth: 200,
            borderColor: ({ color }) => color,
            background: ({ color }) => color,
            color: ({ textColor }) => textColor,
        }
    }
})



function ButtonTitle(props) {
    let { color, children } = props
    const getContrastColor = (hexColor) => (((parseInt(hexColor.substr(1, 2), 16) * 299) + (parseInt(hexColor.substr(3, 2), 16) * 587) + (parseInt(hexColor.substr(5, 2), 16) * 114)) / 1000 >= 128) ? 'black' : 'white';
    const classes = useStyles({ color, textColor: getContrastColor(color ?? "#dadada"), })
    return <Button className={`button-estatus-title ${classes.buttonTitle}`} type='primary' {...props}>{children}</Button>
}

class Prospectos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activesKeysDic: {},
            visible: false,
            prospecto: {},
            prospecto_id: null,
            estatus: {
                data: [],

                page: 1,
                limit: 5,

                total: 0,
                pages: 0,
            },
            prospectosSelected: {},
            selectedEstatus: {},
            activesKeys: [],
            currentEstatus: 0,
            loading: false,
            filters: [],
            visita: null,
            fecha: null,
            probabilidad: null
        }
    }
    refs = []
    /**
     * 
     * @param {*} element prospecto
     * @param {ObjectId} id  del prospecto
     * @param {Number} index del estatus del prospecto seleccionado
     * @returns 
     */
    openDrawer = (element, id, index) => this.setState({ prospecto: element, prospecto_id: id, estatus_index: index, drawer_visible: true, })

    componentDidMount() {

        if (this.props.params.prospecto_id) {
            this.getProspecto(this.props.params.prospecto_id)
        }
        this.getStatus()
        if (this.props.params.asesor_id) this.getAsesor()
        window.addEventListener('scroll', this.handleScroll)
        window.addEventListener('scroll', this.disableScroll, { passive: false });


    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    disableScroll = (event) => {
        event.preventDefault();
    }
    /**
     *
     * @memberof Prospectos
     * @description Maneja la carga de prospectos al hacer scroll en la vista
     */
    handleScroll = () => {
        if (!this.state.loading && Math.ceil(window.innerHeight + document.documentElement.scrollTop) >= (document.documentElement.scrollHeight)) {
            this.updateCurrentEstatus()
        }
    }


    /**
     *
     * @memberof Prospectos
     * @description Obtiene los asesores del proyecto seleccionado
     */
    getAsesor = () => {
        axios.get(`/usuario/${this.props.params?.asesor_id}`)
            .then(({ data }) => {
                this.setState({ asesor: data })
            })
            .catch(error => {
                console.log("error", error)
                message.error("Error al obtener el asesor")
            })
    }

    getProspecto = (id) => {
        axios.get('/prospectos/id', {
            params: {
                id: id
            }
        })
            .then(async ({ data }) => {
                this.openDrawer(data, data._id, 0)
            })
            .catch(error => {
                console.log(error)
                message.error(getResponseError(error.response, 'Error al traer el Prospecto'))
            })
            .finally(() => this.setState({ loading: false, }))
    }

    /**
     *
     * @memberof Prospectos
     * @description Obtiene los estatus del proyecto
     */
    getStatus = ({
        page = this.state.estatus?.page,
        limit = this.state.estatus?.limit,
        filters = this.state.filters
    } = this.state.estatus) => {

        const { proyecto_id } = this.props

        return axios.get('/estatus', {
            params: {
                page,
                limit,
                filters,
                proyecto_id
            }
        })
            .then(({ data }) => {


                this.setState({
                    activesKeys:
                        (page == 1) ? data.data.map(({ _id, nombre }) => !proyecto_id ? nombre : _id) : [...this.state.activesKeys, data.data.map(({ _id, nombre }) => !proyecto_id ? nombre : _id)],
                    estatus: {
                        ...this.state.estatus,
                        ...data,
                        data: (page == 1) ? data.data : [...this.state.estatus?.data, ...data.data]
                    },

                    currentEstatus: 0,
                })

            })
            .catch(error => {
                console.log('error', error);
                message.error("No se pudo obtener los proyectos.")
            })
    }

    /**
     *
     * @memberof Prospectos
     * @description Verifica si los prospectos del estatus actual ya se han cargado en su totalidad
     * En caso contrario, carga los prospectos del estatus siguiente
     */
    obtenerProspectos = async (currentEstatus = this.state.currentEstatus) => {
        let { refs } = this;
        this.setState({ loading: true });

        if (refs[currentEstatus]) {

            //no se han se han cargado datos
            if (refs[currentEstatus]?.isEmpty()) {
                await refs[currentEstatus]?.getProspectos({ page: refs[currentEstatus]?.state?.prospectos?.page })
            }

            if (!refs[currentEstatus]?.isEmpty() && !refs[currentEstatus]?.isFinal()) {

                await refs[currentEstatus]?.getProspectos({ page: refs[currentEstatus]?.state?.prospectos?.page + 1 })

                if (
                    //Si la cantidad de prospectos actual + el limite de prospectos supera o iguala a la cantidad de prospectos total
                    //vamos a actualizar la cantidad de prospectos al siguiente estatus
                    //60+20=60 >= 80
                    refs[currentEstatus]?.state?.prospectos?.next == null
                ) {

                    let currentIndex = 0
                    for (let index = currentEstatus; index < refs.length; index++) {
                        if (refs[index].state?.prospectos?.total > 0 && !refs[currentEstatus]?.isFinal()) {
                            await refs[index].getProspectos()
                            this.setState({ currentEstatus: index })
                            currentIndex = index
                            break
                        }
                    }
                    //si el estatus actual es el penultimo, cargo mas estatus
                    if (currentIndex == this.state.estatus.total - 1)
                        this.getStatus()
                }
            } else {
                this.setState({ currentEstatus: currentEstatus + 1 })
            }
        }
        this.setState({ loading: false })
    }


    /**
     *
     * @memberof Prospectos
     * @description Carga el siguiente estatus con sus prospectos
     */
    loadNext = () => {

        const { refs } = this
        const { currentEstatus } = this.state;

        if (!refs[currentEstatus + 1])
            return null

        refs[currentEstatus + 1].getProspectos()
        this.setState({ currentEstatus: currentEstatus + 1 })
    }

    /**
     *
     * @memberof Prospectos
     * @description Asigna al state todos los estatus activos para cargar datos
     */
    setActivesKeys = (activesKeys) => this.setState({ activesKeys })

    /**
     *
     * @memberof Prospectos
     * @description Verifica que los estatus deshabilitados no se carguen y se lo brinquen
     */
    updateCurrentEstatus = (activesKeys = this.state.activesKeys) => {

        let old_keys = this.state.activesKeys;
        let current = this.state.currentEstatus;

        let __estatus = this.state.estatus?.data;
        // estatus eliminado
        const [key_disabled] = old_keys.filter(key => !activesKeys.includes(key))
        // estatus añadidos
        const [key_enabled] = activesKeys.filter(key => !old_keys.includes(key))


        if (key_disabled) {

            //para cada estatus verificar si ya se cargaron todos los prospectos
            for (let index = 0; index < __estatus.length; index++) {

                const e = __estatus[index];

                if (
                    !(e._id == key_disabled) //no es el que desactive
                    && e.prospectos > 0 //y hay prospectos a cargar
                    && index > current//y estoy en uno superior
                ) {


                    //si no  carge todo lo del actual,me ubico ahi y me salgo
                    this.setState({ currentEstatus: index })
                    current = index;

                    break;
                }

            }
        }
        if (key_enabled) {

            let estatus_index = __estatus.findIndex(e => e._id == key_enabled);
            this.setState({ currentEstatus: estatus_index });
            current = estatus_index;

        }
        this.setActivesKeys(activesKeys)
        this.obtenerProspectos(current)
        return current
    }

    filter = (changed, { fechas, search, visita, probabilidad, fecha }) => {

        if (changed.search) return

        let filters = []
        if (fechas) filters.push({ fechas: fechas.map(f => f._d) })
        if (search) filters.push({ search: search })



        this.setState({ filters, visita, probabilidad, fecha }, () => {
            this.refs.forEach(r => {
                r.getProspectos({ page: 1 })
            })
        })
    }

    /**
     *
     * @memberof Prospectos
     * @description almacena los ids de los prospectos seleccionados
     */
    onSelectProspecto = (prospecto_id) => {
        let prospectosSelected = this.state.prospectosSelected
        if (prospectosSelected[prospecto_id]) {
            delete prospectosSelected[prospecto_id]
        }
        else
            prospectosSelected[prospecto_id] = true


        this.setState({ prospectosSelected })
    }

    /**
     *
     * @memberof Prospectos
     * @description Selecciona los estatus para modificaciones
     */
    selectEstatus = (estatus) => {
        let selectedEstatus = this.state.selectedEstatus

        if (selectedEstatus[estatus])
            delete selectedEstatus[estatus]
        else
            selectedEstatus[estatus] = true

        this.setState({ selectedEstatus })
    }

    /**
     *
     *
     * @memberof Prospectos
     * @param {*} prospecto Objeto del prospecto con sus datos
     * @param {Number} estatus_index indice del estatus donde el prospecto se selecciono para ser editado
     * @param {Boolean} is_otroEstatus Verifica si se cambio el estatus del prospecto
     */
    onUpdateProspecto = (prospecto, estatus_index, old, is_otroEstatus) => {

        if (is_otroEstatus) {
            this.refs[estatus_index].spliceProspecto(prospecto)
            //buscar el estatus  y hacer push
            let estatus = this.state.estatus.data;
            let estatus_actualizar = estatus.findIndex(e => e._id == prospecto.estatus_id._id);
            this.refs[estatus_actualizar].pushProspecto(prospecto)

        }
        else
            this.refs[estatus_index].updateProspecto(prospecto)
    }

    /**
     *
     * @memberof Prospectos
     * @description Resetea los datos de la vista para cargar los datos completos
     */
    refresh = (p) => {
        this.setState(state => {
            state.estatus.data = []
            state.estatus.page = 1
            state.estatus.total = 0
            state.activesKeys = []
            state.currentEstatus = 0
            state.prospecto_id = p?._id
            state.prospecto = p
            return state;
        }, () => this.getStatus())
    }

    updateContador = (index, total) => {
        let estatuses = this.state.estatus.data
        estatuses[index].prospectos = total;

        this.setState({ estatuses })
    }

    render() {

        const {
            proyecto_id,
            loadNext
        } = this.props

        const {
            estatus,
            activesKeys,
            currentEstatus,
            loading
        } = this.state

        const {
            setActivesKeys,
            updateCurrentEstatus,
            getStatus
        } = this

        this.refs = estatus.data.map(e => React.createRef())


        return <>
            <Content className='content'>
                <Spin spinning={loading}>
                    <Card className='width-100'>
                        <PageHeader className="site-page-header  cnt-page-header"
                            title={
                                <Form
                                    className="form-filters"
                                    onValuesChange={this.filter}>
                                    <Row className="width-100" justify="space-between" gutter={[8, 8]}>
                                        <Col span={4} xxl={{ span: 4, order: 1 }} xl={{ span: 4, order: 1 }} lg={{ span: 17, order: 1 }} md={{ span: 14, order: 1 }} sm={{ span: 14, order: 1 }} xs={{ span: 14, order: 1 }}  >

                                            {this.props.params.asesor_id ? <Button type='link' icon={<ArrowLeftOutlined />} onClick={() => this.props.navigate(-1)}></Button> : null}
                                            <span className="ant-page-header-heading-title" title="">
                                                {this.props?.params?.asesor_id ? "Asesor" : "Prospectos Board"}
                                            </span>
                                            {this.props.params?.asesor_id ?
                                                <p className="ant-page-header-heading-title" style={{ fontSize: 14, fontWeight: 'lighter' }}>{`${this.state.asesor?.nombre}`}</p> : null}
                                        </Col>
                                        <Col span={4} xxl={{ span: 4, order: 2 }} xl={{ span: 4, order: 2 }} lg={{ span: 7, order: 2 }} md={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} xs={{ span: 16, order: 2 }} >
                                            {(this.props.proyecto_id !== null) ? <Button disabled={!this.props.prospectos_create} ghost type='primary' icon={<GoPlus />} onClick={() => this.setState({ drawer_visible: true, prospecto: null })}>Nuevo Prospecto</Button> : null}
                                        </Col>
                                        <Col span={4} xxl={{ span: 4, order: 3 }} xl={{ span: 4, order: 3 }} lg={{ span: 9, order: 3 }} md={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 16, order: 3 }} >
                                            <Form.Item noStyle name="visita">
                                                <Select
                                                    className="width-100"
                                                    allowClear
                                                    placeholder="Estatus de Visita"
                                                >
                                                    <Select.Option value={1}>Agendado</Select.Option>
                                                    <Select.Option value={2}>Confirmado</Select.Option>
                                                    <Select.Option value={3}>Concluido</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4} xxl={{ span: 4, order: 3 }} xl={{ span: 4, order: 3 }} lg={{ span: 9, order: 3 }} md={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 16, order: 3 }} >
                                            <Form.Item noStyle name="probabilidad">
                                                <Select
                                                    className="width-100"
                                                    allowClear
                                                    onClear={() => this.setState({ probabilidad: null })}
                                                    placeholder="Ordenar por Score Lead"
                                                >
                                                    <Select.Option value={'1'}><Space><UpOutlined />0-100</Space></Select.Option>
                                                    <Select.Option value={'-1'}><Space><DownOutlined />100-0</Space></Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4} xxl={{ span: 4, order: 3 }} xl={{ span: 4, order: 3 }} lg={{ span: 9, order: 3 }} md={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} xs={{ span: 16, order: 3 }} >
                                            <Form.Item noStyle name="fecha">
                                                <Select
                                                    className="width-100"
                                                    allowClear
                                                    onClear={() => this.setState({ fecha: null })}
                                                    placeholder="Ordenar por Fecha"
                                                >
                                                    <Select.Option value={'1'}><Space><UpOutlined />Mas viejos</Space></Select.Option>
                                                    <Select.Option value={'-1'}><Space><DownOutlined />Mas recientes</Space></Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4} xxl={{ span: 4, order: 6 }} xl={{ span: 4, order: 6 }} lg={{ span: 9, order: 6 }} md={{ span: 12, order: 6 }} sm={{ span: 12, order: 6 }} xs={{ span: 16, order: 6 }} >
                                            <Form.Item noStyle name="fechas">
                                                <RangePicker
                                                    placeholder={['Inicio', 'Fin']} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="pt-1" gutter={[16, 16]}>
                                        <Col md={12} xs={24} >
                                            <Form.Item noStyle name="search">
                                                <Search
                                                    placeholder="Buscar Prospectos por Nombre, Teléfono, Email"
                                                    allowClear
                                                    onSearch={(search) => {
                                                        this.setState({ search }, () => {
                                                            this.refs?.forEach(r => {
                                                                r.getProspectos({ page: 1 })
                                                            })
                                                        })
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            }
                        />
                    </Card>
                    <Collapse
                        className="collapse-estatus width-100"
                        onChange={(key) => updateCurrentEstatus(key)}
                        activeKey={activesKeys}
                        ghost>
                        {estatus.data.map(({ _id, nombre, color, ...args }, index) => {
                            return (
                                <Panel
                                    className='collapse-estatus-panel'
                                    showArrow={false}
                                    header={<ButtonTitle
                                        size="large"
                                        color={color}
                                        icon={<LeftOutlined
                                            className="collapse-estatus-arrow"
                                        />}
                                    >
                                        <Checkbox
                                            disabled={!this.props.prospectos_edit && !this.props.prospectos_delete}
                                            checked={this.state.selectedEstatus[_id]}
                                            className='mr-1 ml-1'
                                            onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                this.selectEstatus(_id)
                                            }}
                                        />
                                        <Space direction="horizontal">
                                            {nombre}
                                            &nbsp;
                                            <small>
                                                <i>
                                                    {[args.prospectos]}
                                                </i>
                                            </small>
                                        </Space>

                                    </ButtonTitle>}
                                    key={!proyecto_id ? nombre : _id}>
                                    <ListaProspectos
                                        {...args}
                                        _id={_id}
                                        asesor_id={this.props.params?.asesor_id}
                                        nombre={nombre}
                                        color={color}
                                        currentEstatus={currentEstatus}
                                        index={index}
                                        ref={ref => this.refs[index] = ref}
                                        loadNext={this.loadNext}
                                        openDrawer={this.openDrawer}
                                        filters={this.state.filters}
                                        visita={this.state.visita}
                                        probabilidad={this.state.probabilidad}
                                        fecha={this.state.fecha}

                                        search={this.state.search}
                                        selectAll={this.state.selectedEstatus[_id]}
                                        deselectAll={() => this.selectEstatus(_id)}
                                        setLoading={(value) => this.setState({ loading: value })}
                                        onLoadDataTotal={(total) => this.updateContador(index, total)}
                                        onSelectProspecto={(prospecto_id) => this.onSelectProspecto(prospecto_id)}
                                        prospectosSelected={this.state.prospectosSelected}
                                    />
                                </Panel>
                            )
                        })}
                    </Collapse>
                </Spin>
            </Content>
            <DrawerProspectos
                onClose={() => {
                    this.setState({
                        prospecto_id: null,
                        prospecto: {},
                        drawer_visible: false,
                    })
                }}
                onSelectProspecto={(prospecto_id) => this.onSelectProspecto(prospecto_id)}
                estatus_index={this.state.estatus_index}
                onCreateProspecto={(p) => this.refresh(p)}
                onUpdateProspecto={(p) => this.refresh(p)}
                visible={this.state.drawer_visible}
                prospecto_id={this.state.prospecto?._id}
                prospecto={this.state.prospecto}
            />

            <Affix offsetBottom={10}>
                <BtnProspectos
                    prospectosSelected={Object.keys(this.state.prospectosSelected)}
                    refresh={() => {
                        this.refs.forEach(r => {
                            r.getProspectos()
                        })
                        this.setState({ prospectosSelected: {} })
                    }}
                    updateProspectos={() => {
                        this.refs.forEach(r => {
                            r.getProspectos()
                        })
                        this.setState({ prospectosSelected: {} })
                    }}
                    proyecto={this.props.proyecto_id}
                />
            </Affix>

        </>
    }

}

export default function ProspectosWrapper(props) {

    let proyecto_id = React.useContext(ProyectoId)
    const navigate = useNavigate()
    let params = useParams()

    const user = useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        prospectos_edit: ['prospectos', 'edit'],
        prospectos_create: ['prospectos', 'create'],
        prospectos_delete: ['prospectos', 'delete']
    })

    let refProspectos = React.useRef()

    useEffect(() => {
        refProspectos?.current?.refresh()
        refProspectos?.current?.getStatus({ page: 1 })
    }, [proyecto_id])

    return <Prospectos
        {...props}
        {...permisos}
        ref={refProspectos}
        proyecto_id={proyecto_id}
        navigate={navigate}
        params={params}
    />
}