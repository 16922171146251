import React, { Component, useState, useEffect } from "react";
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Tooltip, Popover, Input, Checkbox, Radio, Avatar as AvatarD } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined, BuildOutlined, BulbOutlined, RightSquareFilled, FacebookFilled } from "@ant-design/icons";


import { useLogin } from 'react-facebook'


import Logged from '../../../Hooks/Logged'
import usePermissions from "../../../Hooks/usePermissions";

//componentes
import Avatar from "../../Widgets/Avatar/Avatar";

//modales
import ModalProyectos from "./ModalProyectos"
import ModalEnlaceFacebook from "./ModalEnlaceFacebook"
import HeaderAdmin from "../../Header/HeaderAdmin"
import { useStyleButton, getContrastColor } from '../../Widgets/Button'
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";

const { Content } = Layout
const { Text, Title } = Typography

function RedesSociales({ FacebookLogin, _id }) {

    const classes = useStyleButton({
        color: "#4267B2",
        textColor: getContrastColor("#4267B2")
    })

    const [redesSociales, setRedesSociales] = useState({
        data: [],
        page: 1,

        limit: 5,
        total: 0,
    })

    const [value, setValue] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getRedesSociales()
    }, [])

    /**
     * 
     * @method facebookLogin
     * @returns Obtiene la lista de redes sociales
     */
    const getRedesSociales = ({ page = redesSociales.page, limit = redesSociales.list, search = redesSociales.search } = redesSociales) => {
        setRedesSociales({ ...redesSociales, page, limit, search })

        console.log('search', search);
        setLoading(true)
        axios.get('/redes-sociales', {
            params: {
                page,
                limit,
                proyecto_id: _id,
                search
            }
        })
            .then(({ data }) => {
                console.log('e', data);
                setRedesSociales(data)
                setValue(data?.red_social?._id)
            })
            .catch((response) => {
                console.log('response', response)
            })
            .finally(e => setLoading(false))
    }

    /**
     * 
     * @method facebookLogin
     * @returns Inicia sesión con facebook y actualiza la lista de páginas de facebook
     */
    const facebookLogin = () => {
        setLoading(true)
        FacebookLogin.login({
            scope: 'public_profile,email,pages_show_list,ads_management,leads_retrieval,pages_read_engagement,pages_manage_metadata,pages_manage_ads,public_profile',
            auth_type: 'reauthenticate'
        })
            .then(response => {
                axios.post('/redes-sociales/cuentas', {
                    response: response?.authResponse,
                    proyecto_id: _id
                })
                    .then(response => {
                        getRedesSociales()
                    })
                    .catch(error => {
                        console.log('ERROR', error)
                        message.error("No es posible obtener las páginas de Facebook.")
                    })
            })
            .catch(error => {

                console.log('error', error);
            })
            .finally(e => setLoading(false))
    }

    /**
     * 
     * @method bindProyecto
     * @returns Enalza una red social a un proyecto.
     */
    const updateBinding = (red_social_id) => {
        setLoading(true)

        console.log('red_social_id == value', red_social_id)
        console.log('red_social_id == value', red_social_id)
        console.log('red_social_id == value',)
        axios.post('/redes-sociales/' + ((value == red_social_id) ? 'unbind' : 'bind'), {
            proyecto_id: _id,
            red_social_id
        })
            .then(({ data }) => {
                getRedesSociales()
            })
            .catch((response) => {

            })
            .finally(e => setLoading(false))

    }

    console.log('value', value);

    return (
        <Radio.Group value={value}>
            <List
                size="small"
                bordered={false}
                dataSource={redesSociales.data}
                loading={loading}
                pagination={{
                    page: redesSociales.page,
                    pageSize: redesSociales.limit,
                    total: redesSociales.total,
                    size: 'small',
                    onChange: (page, limit) => getRedesSociales({ page, limit })
                }}
                header={<Input.Search placeholder="Buscar Pagina de Facebook" onSearch={search => getRedesSociales({ search })} />}
                renderItem={(item, index) => <List.Item>
                    <Radio
                        onClick={() => updateBinding(item._id)}
                        value={item._id}>
                        <List.Item.Meta
                            avatar={<Avatar size="small" 
                            src={`${axios.defaults.baseURL}/redes-sociales/${item?._id}?avatar=${item.avatar?.file}&Authorization=${encodeURI(sessionStorage.getItem("token"))}`} />}
                            title={item.name}
                            style={{ minWidth: 250 }}
                        />
                    </Radio>
                </List.Item>}
            />
            <Button loading={loading} onClick={facebookLogin} className={classes.buttonTitle} style={{ marginTop: "1em" }} block icon={<FacebookFilled />}>ENLAZAR CON FACEBOOK</Button>
        </Radio.Group>
    )



}

/**
 * 
 * 
 * @param {*} props 
 * @component ButtonSocialNetwork
 * @description 
 * @returns el PopOvver que renderiza la lista de paginas de facebook.
 */
function ButtonSocialNetwork(props) {
    const FacebookLogin = useLogin();


    const [showSelector, setShowSelector] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    /**
     * 
     * @method ButtonSocialNetwork
     * @returns Al hacer click en el botón, quita el tooltip y muestra o quita el popover
     */
    const handleButtonClick = () => {
        setShowTooltip(false)
        setShowSelector(!showSelector)
    }

    /**
     * 
     * @method handleTooltipHover
     * @returns Muestra el tooktip
     */
    const handleTooltipHover = (visible) => {
        if (!showSelector) setShowTooltip(visible);
    }

    /**
     * 
     * @method handlePopoverVisibility
     * @returns Muestra el tooktip
     */
    const handlePopoverVisibility = (visible) => {
        if (!visible) setShowSelector(false);
    }

    return (
        <Popover
            open={showSelector || FacebookLogin.isLoading}
            onOpenChange={handlePopoverVisibility}
            trigger={'click'}
            destroyTooltipOnHide
            content={<RedesSociales {...props} FacebookLogin={FacebookLogin} />}
        >
            <Tooltip
                disabled={!props.linkFacebook}
                open={showTooltip}
                onOpenChange={handleTooltipHover}
                title="Enlazar con Facebook"
            >
                <Button
                    disabled={!props.linkFacebook}
                    type="primary"
                    icon={<FacebookFilled />}
                    onClick={handleButtonClick}
                />
            </Tooltip>
        </Popover>
    );
}


/**
 *
 *
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista donde se listan todos los proyectos
 */
class Proyectos extends Component {

    /**
     *Creates an instance of proyectos.
     * @param {*} props
     * @memberof Proyectos
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible_modal: false,
            proyectos: {
                data: [],
                limit: 24,
                page: 1,

                total: 0,
                pages: 0,
            },
            proyecto_id: null,

            modalEnlaceFacebook: false
        };
    }

    componentDidMount() {
        this.getProyectos();
    }

    /**
     *
     * @memberof Proyectos
     * @method getProyectos
     * @description Obtiene el listado de proyectos.
     */
    getProyectos = async (
        {
            page = this.state.proyectos.page,
            limit = this.state.proyectos.limit,
            search = this.props.search,
        } = this.state.proyectos
    ) => {
        this.setState({
            loading: true,
            proyectos: { ...this.state.proyectos, page, limit },
        })

        axios.get("/proyectos", { params: { limit, page, search } })
            .then(({ data }) => {
                this.setState({
                    proyectos: { ...this.state.proyectos, ...data },
                })
            })
            .catch((error) => {
                message.error("Error al obtener los proyectos")
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof Proyectos
     * @method deleteProyecto
     * @description Elimina un proyecto por su id y vuelve a obtener el listado.
     */
    deleteProyecto = (id) => {
        axios.delete("/proyectos/", { params: { id } })
            .then((response) => {
                message.success('Proyecto eliminado');
                window.location.reload()
            })
            .catch((error) => {
                message.error(error?.response?.data ?? "No fue posible eliminar")
            })
            .finally(() => {
                this.getProyectos();
            })
    }

    renderUrl = (item) => {
        const urlParams = new URL(axios.defaults.baseURL + "/proyectos/logo/" + item._id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("name", item.logo?.name)
        return urlParams.href
    }

    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Content className='content'>
                        <HeaderAdmin
                            title="Settings"
                        />
                        <List
                            grid={{ gutter: 16, column: 3 }}
                            className="component-list "
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin proyectos" }}
                            dataSource={this.state.proyectos.data}
                            pagination={{
                                current: this.state.proyectos.page,
                                pageSize: this.state.proyectos.limit,
                                total: this.state.proyectos.total,
                                hideOnSinglePage: false,
                                position: "bottom",
                                showSizeChanger: true,
                                pageSizeOptions: [12, 24, 64, 128, 512],
                                onChange: (page, limit) => this.getProyectos({ page, limit }),
                            }}
                            renderItem={(item) => (
                                <List.Item className="component-list-item" key={item._id}>
                                    <Card className="card-list">
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24}>
                                                <Row wrap={false} gutter={[16, 16]}>
                                                    <Col flex="none">
                                                        <Avatar
                                                            size={50}
                                                            color={item.color}
                                                            url={item.logo ? {
                                                                url: axios.defaults.baseURL + '/proyectos/' + item._id,
                                                                logo: item.logo?.name
                                                            } : null}
                                                            name={item.nombre}

                                                        />
                                                    </Col>
                                                    <Col flex="auto"
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Title level={3} style={{ marginBottom: 0 }} ellipsis>{item.nombre}</Title>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={24} style={{ height: 40 }}>
                                                <Text ellipsis={{ rows: 2, expandable: false }}>
                                                    {item.descripcion}
                                                </Text>
                                            </Col>
                                            <Col xs={24}>
                                                <Space size={8} direction="horizontal" className="center">
                                                    <Tooltip title="Editar Proyecto">
                                                        <Button
                                                            type="primary"
                                                            disabled={!this.props.editProyectos}
                                                            icon={<EditOutlined style={{ color: "currentcolor" }} />}
                                                            onClick={() => this.setState({
                                                                visible_modal: true,
                                                                proyecto_id: item._id,
                                                            })}
                                                        />
                                                    </Tooltip>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este proyecto?"
                                                        disabled={!this.props.deleteProyectos}
                                                        onConfirm={() => this.deleteProyecto(item._id)}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            disabled={!this.props.deleteProyectos}
                                                            title="Eliminar"
                                                            icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
                                                        />
                                                    </Popconfirm>
                                                    <ButtonSocialNetwork
                                                        {...item}
                                                        {...this.props}
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>
                    <ModalProyectos
                        visible={this.state.visible_modal}
                        proyecto_id={this.state.proyecto_id}
                        onClose={() => {
                            window.location.reload()
                            this.getProyectos();
                            this.setState({
                                visible_modal: false,
                                proyecto_id: undefined,
                            });
                        }}
                    />
                    {
                        this.props.createProyectos && <FloatingButton
                            onClick={()=>this.setState({ visible_modal: true,proyecto_id: undefined, })}
                        />
                    }
                    {/* <ModalEnlaceFacebook 
                        visible={visible}
                        onClose={}
                    /> */}
                </Spin>
            </>
        );
    }
}

export default function (props) {


    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        createProyectos: ["proyectos", "create"],
        editProyectos: ["proyectos", "edit"],
        readProyectos: ["proyectos", "read"],
        deleteProyectos: ["proyectos", "delete"],
        linkFacebook: ["proyectos", 'link-facebook']
    })


    return <Proyectos {...props} {...permissions} />
}