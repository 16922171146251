import React, { Component } from "react";
import { Row, Modal, Form,  Spin,  } from "antd";




/**
 * @class ModalProyectos
 * @description Modal para el CRUD de proyectos
 */
class ModalProyectos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: undefined,
            loading: false,
            loadingImage: false,
            estatus: []
        };
    }

    formRef = React.createRef();

    componentDidMount() {

    }


    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-proyecto"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row align="center">
                        asfdasdf
                        asfdasdfasdf
                    </Row>
                </Form>
            </Spin>
        );
    }
}

export default function Vista(props) {
    const { visible = false, onClose = () => { } } = props;

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            title={props.proyecto_id ? "Editar Proyecto" : "Crear Proyecto"}
            closable={true}
            destroyOnClose={true}
            zIndex={1000}
            width={1000}
            cancelText="Cancelar"
            okText="Guardar"
            okButtonProps={{
                form: "form-proyecto",
                key: "submit",
                htmlType: "submit",
            }}
        >
            <ModalProyectos {...props} />
        </Modal>
    );
}
