import React, { Component } from "react";
import { Select, Row, Col, Modal, Spin, Form, Button, message, DatePicker, } from 'antd';
import axios from "axios"
import { getResponseError } from "../../../Utils";
import moment from 'moment'
const { Option } = Select;

/**
 *
 *
 * @export
 * @class FormVisita
 * @extends {Component}
 */
class FormVisita extends Component {



    constructor(props) {
        super(props)
        this.state = {
            proyectos: {
                data: [],
                page: 1,
                total: 0
            },
            prospecto: {
                asignado_a: [
                    {
                        _id: '',
                        nombre: ''
                    }
                ]
            },
            horarios: [],
            loading: false,
        }

    }

    formRef = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProyectos()
        this.getProspecto()
        this.hours()
    }

    getProspecto = () => {
        this.setState({ loading: true })
        axios.get('/prospectos/id', {
            params: {
                id: this.props.prospecto_id
            }
        }).then(({ data }) => {
            this.setState({ prospecto: data })
            this.formRef.current?.setFieldsValue({
                proyecto_id: data.proyecto_id ? {
                    value: data.proyecto_id._id,
                    label: data.proyecto_id.nombre
                } : null
            })
        }).catch(error => {
            console.log("error", error)
            message.error(getResponseError(error.response, "Error al obtener el prospecto"))
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @methodOf FormVisita
     *
     * @function getProyectos
     * @description Obtiene los proyectos registrados en el sistema
     * */
    getProyectos = () => {
        this.setState({ loadingProyectos: true })
        axios.get("/proyectos", {
            params: {
                page: 1,
                limit: 20,
            }
        }).then(({ data }) => {
            console.log("response", data)
            this.setState({ proyectos: data })
        }).catch(error => {
            console.log("error", error);
            message.error(getResponseError(error.response, "Error al obtener los proyectos"))

        }).finally(() => this.setState({ loadingProyectos: false }))

    }


    /**
    *
    * @methodOf FormVisita
    *
    * @function addCita
    * @description Añade una cita
    * */
    addCita = (values) => {
        this.setState({ loading: true })
        axios.post('/citas/add', {
            fecha: moment(values.fecha),
            descripcion: "Visita Agendada",
            hora: values.hora,
            proyecto_id: values.proyecto_id,
            prospecto_id: this.props.prospecto_id,
            asesor_id: values.asignado_a,
        })
            .then(({ data }) => {
                message.success("Cita Guardada")
                this.props.onClose()
            })
            .catch(error => {
                message.error(getResponseError(error.response, "No es posible las guardar la cita."))
            })
            .finally(() => this.setState({ loading: false }))

    }


    /**
     *
     *
     * @param {*} current
     * @returns
     * @memberof FormVisita
     * @description Deshabilita las fechas posteriores a hoy
     */
    disabledDates(current) {
        return current && current < moment().subtract(1, 'days');
    }


    hours = (date, dateString) => {

        const horarios = [];

        let fecha = moment();

        //si la fecha es otra a hoy, se ponen todos los horarios
        if (!fecha.isSame(moment(date))) {
            horarios.push('00:00')
            fecha = moment().startOf('day')
        }
        // Fecha inicial y final para generar los horarios
        const currentTime  = fecha;
        const nextHalfHour = fecha.add(30 - (currentTime.minute() % 30), 'minutes');

        while (nextHalfHour.isBefore(moment().endOf('day'))) {
            horarios.push(nextHalfHour.format('HH:mm'));
            nextHalfHour.add(30, 'minutes');
        }


        this.setState({ horarios })
    }


    /**
     * @memberof ProspectoModal
     * @method onValuesChange
     * 
     * @description Guardamos los formualrios
     * 
     */
    submit = (values) => {
        if (values.proyecto_id?.value) values.proyecto_id = values.proyecto_id.value
        this.addCita(values)
    }

    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    onFinish={this.submit}
                    ref={this.formRef}
                    initialValues={{
                        fecha: moment()
                    }}
                >
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                            >

                                <DatePicker className="width-100" placeholder="Fecha" disabledDate={this.disabledDates} onChange={this.hours} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Hora"
                                name="hora"
                                rules={[
                                    {
                                        required: true,
                                        message: "Seleccione un horario para la cita",
                                    },
                                ]} 
                            >
                                <Select placeholder="Horario">
                                    {this.state.horarios?.map(e => <Option value={e} key={e}>
                                        {e}
                                    </Option>)}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="asignado_a"
                                label="Asesor"
                                rules={[
                                    {
                                        required: true,
                                        message: "Seleccione un asesor",
                                    },
                                ]} >

                                <Select>
                                    {this.state.prospecto.asignado_a?.map(e => <Option value={e._id} key={e._id}>
                                        {e.nombre}
                                    </Option>)}

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Proyecto"
                        name="proyecto_id"
                        hidden={this.props.proyecto ? true : false}
                    >
                        <Select
                            className="width-100"
                            placeholder="Selecciona proyecto"
                            optionLabelProp="label"
                            mode="single"
                        >
                            {this.state.proyectos.data?.map(proyecto => <Option key={proyecto._id} value={proyecto._id} label={proyecto.nombre} >
                                {proyecto.nombre}
                            </Option>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item className="center">
                        <Button type="primary" htmlType="submit" >
                            GUARDAR
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>

        );
    }

};

export default function (props) {


    return <Modal
        open={props.visible}
        onCancel={props.onClose}
        closable={true}
        footer={false}
        destroyOnClose={true}
        width={400}
        title={<span className="center">Agendar Visita</span>}
    >
        <FormVisita {...props} />
    </Modal >
}


