import React, { Component, useState, useEffect } from "react";
import { Typography, Button, Layout, Space, message, Avatar, Drawer, Card } from "antd";
import axios from "axios";
import { CloseOutlined } from "@ant-design/icons";

import { GrapesjsReact, } from "grapesjs-react";
import Title from "antd/lib/typography/Title";
import GrapesJSPresetNewsletter from 'grapesjs-preset-newsletter';
import GrapesJSBlockBasic from 'grapesjs-blocks-basic';


// import 'grapesjs-react/';
import "../../Styles/Theme/GrapeJS/main.scss";


function eliminarScripts(htmlString) {
    // Crear un nuevo DOMParser
    const parser = new DOMParser();
    // Parsear el string HTML
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Buscar todos los elementos <script>
    const scripts = doc.getElementsByTagName('script');

    // Iterar sobre todos los elementos <script> en orden inverso
    // para evitar problemas con los índices al eliminarlos
    for (let i = scripts.length - 1; i >= 0; i--) {
        scripts[i].parentNode.removeChild(scripts[i]);
    }

    // Devolver el HTML como string
    return doc.body.innerHTML;
}


export default class EmailEditor extends React.Component {


    state = {
        visible: false,
        editor: false,
        contentHTML: null,
        imagesList: []
    }

    setVisible = visible => this.setState({ visible })

    setEditor = editor => this.setState({ editor })

    setContentHTML = contentHTML => this.setState({ contentHTML })

    setImagesList = imagesList => this.setState({
        imagesList: imagesList.map(e => ({
            ...e,
            ...e.file,
            src: axios.defaults.baseURL + '/email-templates-images/' + e._id
        }))
    })

    getImagesList = () => this.state.imagesList

    getContentHTML = () => this.state.contentHTML

    render() {

        const { visible, editor, contentHTML, imagesList } = this.state
        const { setVisible, setEditor, setContentHTML, setImagesList } = this

        return <>
            {contentHTML ? <div
                style={{
                    borderRadius: 10
                }}
                onClick={() => setVisible(true)}
                dangerouslySetInnerHTML={{ __html: contentHTML }} />
                :
                <Card
                    onClick={() => setVisible(true)}
                    style={{
                        padding: '36px 24px',
                        borderStyle: "3px solid dashed"

                    }}>
                    <Title level={3} align="center">Seleccione para Iniciar el Editor de Correo Electrónico</Title>
                </Card>}

            <Drawer
                className="drawer-editor"
                placement="bottom"
                visible={visible}
                onClose={() => {
                    console.log('EDITOR', editor);
                    setVisible(false)
                }}
                destroyOnClose={true}
                title="Editando el Temaplte"
                size="default"
                height="100vh"
                closeIcon={null}
                closable={false}
                keyboard={false}
                maskClosable={false}
                extra={<Space>
                    <Button icon={<CloseOutlined />} danger type="primary" onClick={() => {
                        setContentHTML(eliminarScripts(editor.Commands.run('gjs-get-inlined-html')))
                        setVisible(false)
                        setImagesList(editor.Assets.getAll().models.map(e => e.attributes))
                    }}>Cerrar Editor</Button>
                </Space>}
            >
                <GrapesjsReact
                    id='grapesjs-react'
                    customUI={false}
                    // from
                    stylePrefix="hms-"
                    width="100%"
                    plugins={[
                        GrapesJSPresetNewsletter,
                        GrapesJSBlockBasic,
                    ]}
                    storageManager={false}

                    canvas={{
                        styles: [
                            // 'https://fonts.googleapis.com/css?family=Archivo+Narrow:400,400i,700,700i|Roboto:300,300i,400,400i,500,500i,700,700i&subset=latin,latin-ext',
                            'https://fonts.googleapis.com/css?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap',
                            'https://fonts.googleapis.com/css?family=Roboto:100,300,400,700'
                        ]
                    }}
                    styleManager={{
                        sectors: [{
                            name: 'General',
                            open: false,
                            buildProps: ['font-family'],
                            properties: [
                                {
                                    name: 'Font',
                                    property: 'font-family',
                                    options: [
                                        // { value: '', name: 'Default' },
                                        // { value: 'Arial, sans-serif', name: 'Arial' },
                                        {value: '', name: 'Default'},
                                        { value: "Roboto, sans-serif", name: 'Roboto' },
                                        // { value: "Roboto, sans-serif", name: 'Roboto' },
                                        { value: "'Poppins', sans-serif", name: 'Poppins' },
                                        // { value: 'MiFuentePersonalizada, sans-serif', name: 'MiFuentePersonalizada' }
                                        //... tus otras fuentes aquí ...
                                    ]
                                },
                                //... tus otras propiedades aquí ...
                            ]
                        }]
                    }}

                    onInit={({ editor }) => {
                        // Obtenemos el componente de texto nativo
                        var originalText = editor.Components.getType('text');
                        // Extendemos el componente de texto nativo
                        editor.Components.addType('text', {
                            model: {
                                ...originalText.model,
                                defaults: {
                                    ...originalText.model.prototype.defaults,
                                    traits: [
                                        ...originalText.model.prototype.defaults.traits,
                                        {
                                            type: 'select',
                                            label: 'Variables',
                                            name: 'select-variable',
                                            options: this.props.variables.map(e => ({ id: e.value, name: e.name })),
                                            changeProp: 1,
                                        },
                                    ],
                                },
                                init() {
                                    this.on('change:select-variable', () => {
                                        const selectValue = this.get('select-variable')
                                        this.append(`{{${selectValue}}}`);
                                    });
                                },
                            },
                            view: originalText.view,
                        })

                        editor.Blocks.add('ProjectImage', {
                            label: 'Imagen de Proyecto',
                            attributes: {
                                class: 'gjs-fonts gjs-f-image'
                            },
                            content: {
                                type: 'image',
                                src: '{{ imageProject }}',
                                style: {
                                    background: 'gray',
                                    'border-radius': '100px'
                                },
                                traits: [
                                    {
                                        type: 'text',
                                        label: 'Alt',
                                        name: 'alt',
                                        placeholder: 'alt text',
                                    },
                                ],
                            },
                        });


                        setEditor(editor)
                        editor.on('asset:remove', ({ attributes }) => {
                            axios.delete('/email-templates-images/' + attributes._id)
                                .then(e => message.success("Imagen Eliminada"))
                                .catch(e => message.error("No se pudo eliminar"))
                        })


                        editor.setComponents(contentHTML)
                        editor.Assets.add(imagesList)





                        // var originalText = editor.Components.getType('text');
                        // console.log('originalText.model.prototype.defaults', originalText.model.prototype.defaults);
                        // Extendemos el componente de texto nativo

                        window.editor = this //.state.editor
                    }}


                    assetManager={{
                        upload: axios.defaults.baseURL + '/email-templates-images',
                        uploadName: "file",
                        headers: {
                            'Authorization': sessionStorage.getItem("token")
                        },
                        params: {
                            ...this.props.email_template_id ? { email_template_id: this.props.email_template_id } : {}

                        }
                    }}
                />
            </Drawer>

        </>
    }
}