import React, { useEffect } from "react";
import { Card } from "antd";
import JsonViewer from 'react-json-view'

export default function ObjectInput({ value, onChange }) {
    const [obj, setObj] = React.useState({});

    useEffect(() => {

        if (value)
            setObj(value)

    }, [value])


    const handleJsonChange = (event, ...args) => {
        console.log('event', event);
        console.log('args', args);
        try {
            //   const inputJson = JSON.parse(event.target.value);
            //   setObj(inputJson);
            //   setObj(event.new_value)
            onChange(event.updated_src)
        } catch (error) {
            // manejar error de parsing JSON
            console.error('Actualizando: ', error);
        }
    }


    return (
        <Card style={{ border: '1px solid #d9d9d9',  display: "block", zIndex: 100}}>
            <JsonViewer
                // shouldCollapse
                collapsed={false}
                // style={{minHeight: 100, zIndex: 1000}}
                sortKeys
                src={obj}
                onEdit={handleJsonChange}
                onDelete={handleJsonChange}
                onAdd={handleJsonChange}
                displayObjectSize={true}
                name={"params"}
            />
        </Card>
    );
}
