import React from 'react'
import { Card, Tag, Space, Typography, Row, Col, Tooltip, Avatar } from 'antd';
import { CgCloseO, } from 'react-icons/cg';
import { MaxMin } from '../../Widgets/Iconos';
import { BsCheckCircle, BsFillPatchCheckFill } from 'react-icons/bs';
import { BsPeopleFill } from "react-icons/bs";
import { SlEarphonesAlt } from "react-icons/sl";
import { WhatsAppOutlined } from "@ant-design/icons";
import { MdSecurity } from "react-icons/md"
import moment from 'moment';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import axios from 'axios';

const { Text } = Typography;

export default function CardProspecto({
    task,
    _id = null,
    title = "Nombre",
    email = "Email",
    telefono = "telefono",
    fecha = new Date(),
    porcentaje = 0,
    asesores = [],
    proyecto = {},
    fuente = null,
    citas = [],
    onClick = () => { },
}) {

    const generateName = (value) => {
        switch (value) {
            case 1:
                return "Humanistics"
            case 2:
                return "Facebook"
            case 3:
                return "Instagram"
            case 4:
                return "Formulario de contacto"
            case 5:
                return "Call Center"
            case 6:
                return "Guardia"
            case 7:
                return "Referido"

            case 8:
                return "WhatsApp"

            default:
                return "No asignado"

        }
    }

    const generateImage = (fuente) => {
        const images = [{
            src: '/img/humanistics.png'
        }, {
            src: '/img/f_logo_RGB-Blue_512.png'
        }, {
            src: '/img/instagram.png'
        }, {
            src: '/img/humanistics.png'
        }, {
            src: <SlEarphonesAlt />,
            style: { backgroundColor: '#dadada' }
        }, {
            src: <MdSecurity />,
            style: { backgroundColor: '#f56a00' }
        }, {
            src: <BsPeopleFill />,
            style: { backgroundColor: '#87d068' }
        }, {
            src: <WhatsAppOutlined />,
            style: { backgroundColor: '#00ff00' }

        }]

        return images[fuente - 1]

    }

    const generatTag = (value) => {

        let tag = {
            number: 0,
            icon: <CgCloseO />
        }

        if (value >= 0 && value < 10) {
            tag.number = 1;
            tag.icon = <CgCloseO />;
        }

        if (value >= 10 && value < 20) {
            tag.number = 2;
            tag.icon = <CgCloseO />;
        }

        if (value >= 20 && value < 30) {
            tag.number = 3;
            tag.icon = <CgCloseO />;
        }

        if (value >= 30 && value < 40) {
            tag.number = 4;
            tag.icon = <CgCloseO />;
        }

        if (value >= 40 && value < 50) {
            tag.number = 5;
            tag.icon = <MaxMin />;
        }

        if (value >= 50 && value < 60) {
            tag.number = 6;
            tag.icon = <MaxMin />;
        }

        if (value >= 60 && value < 70) {
            tag.number = 7;
            tag.icon = <BsCheckCircle />;
        }

        if (value >= 70 && value < 80) {
            tag.number = 8;
            tag.icon = <BsCheckCircle />;
        }

        if (value >= 80 && value < 90) {
            tag.number = 9;
            tag.icon = <BsCheckCircle />;
        }
        if (value >= 90) {
            tag.number = 10;
            tag.icon = <BsCheckCircle />;
        }

        return <Tag icon={tag.icon} className={`hm-card-prospecto-tag hm-card-prospecto-color-${tag.number}`}> {value} % </Tag >
    }

    return (

        <Card
            title={
                <Space>
                    {Array.isArray(citas) && citas.length > 0 ?
                        <BsFillPatchCheckFill style={{ color: '#A22E9B' }} />
                        : null}
                    <span>{title}</span>
                </Space>}
            className="mb-1 hm-card-prospecto"
            extra={
                <Space.Compact direction="vertical" >
                    <label className='text-center hm-card-extra-tiny-text'> {moment(fecha).format('L')}</label>
                    <label className='text-center hm-card-extra-tiny-text'> {moment(fecha).format('LT')}</label>
                </Space.Compact>}
            onClick={() => onClick(task, proyecto?._id)}
            hoverable
            loading={false}
            id={_id}
            key={_id}

        >
            <Row align="top">
                <Col span={24}>
                    <Text ellipsis className='hm-card-prospecto-text-light'>{email} </Text><hr className='separator' /><Text ellipsis className='hm-card-prospecto-text-light '>{telefono}</Text>
                </Col>
            </Row>
            <Row >
                <Col span={12}>
                    {generatTag(porcentaje)}
                </Col>
                <Col span={12}>

                    <Space.Compact direction="horizontal" >
                        <Avatar.Group 
                        maxCount={2} 
                        size="small" 
                        gap={1}
                        maxStyle={{
                            color: '#fff',
                            backgroundColor: 'rgb(110, 33, 175)',
                          }}
                        >
                            {asesores.length > 0 ? asesores?.map(_asesor =>
                                <Tooltip title={_asesor?.nombre} placement="top">
                                    <Avatar
                                        size="small"
                                        color={_asesor?.color}
                                        style={{ backgroundColor: _asesor?.color }}
                                        src={(_asesor?.avatar !== "" && _asesor?.avatar !== undefined) ? axios.defaults.baseURL + '/upload/' + _asesor?.avatar : ""}
                                    >{_asesor?.nombre?.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</Avatar>
                                </Tooltip>
                            ) : null}
                        </Avatar.Group>


                        <Tooltip title={proyecto?.nombre} placement="top">
                            <CustomAvatar size="small" name={proyecto?.nombre} source={proyecto?.logo ? `${axios.defaults.baseURL}/upload/${proyecto?.logo}` : undefined} color={proyecto?.color} />
                        </Tooltip>
                        <Tooltip title={generateName(fuente)} placement="top">
                            <Avatar size="small" {...generateImage(fuente)} />
                        </Tooltip>
                    </Space.Compact>
                </Col>
            </Row>
        </Card >
    )
}