import React, { useState, useEffect } from 'react';
import { Input } from 'antd'
import {PhoneOutlined} from '@ant-design/icons'

const PhoneInput = (props) => {

    const { value, onChange, icon = undefined, className,onBlur, disabled} = props

    const [inputValue, setInputValue] = useState(value)

    /**
     *
     * @method handleInput
     * @param {object} e
     * @description Maneja el input
     */
    const handleInput = (e) => {
        const phone = formatNumber(e.target.value)
        setInputValue(phone)
        onChange(phone)
    }

    useEffect(() => {
        const formatear = formatNumber(value)
        setInputValue(formatear)
    }, [value])

    return (
        <Input  prefix={<PhoneOutlined/>} disabled={disabled} className={className} onChange={(e=>handleInput(e))} onBlur={onBlur} value={inputValue}/>
    );
}

function formatNumber(value){
	if(!value) return value

	const phoneNumber = value.replace(/[^\d]/g,'');
    const phoneNumberLength = phoneNumber.length;

    if(phoneNumberLength < 4) return phoneNumber;
    if(phoneNumberLength < 7){
        return `+${phoneNumber.slice(0,2)} ${phoneNumber.slice(2,5)} ${phoneNumber.slice(5)}`
    }
    return `+${phoneNumber.slice(0,2)} ${phoneNumber.slice(2,5)} ${phoneNumber.slice(5,8)} ${phoneNumber.slice(8,12)}`
}


export default PhoneInput;