import { Checkbox, List, Row, Typography, Tooltip, Col, Space, message, Avatar as AntDAvatar, Badge } from "antd";
import React, { Component, useContext } from "react";
import axios from "axios";
import moment from 'moment'
import Avatar from '../../Widgets/Avatar/Avatar'
import { generateTag, getResponseError } from "../../Utils";
import { ProyectoId } from '../../../Hooks/Proyecto';
import { SlEarphonesAlt } from "react-icons/sl";
import { MdSecurity } from "react-icons/md";
import { BsPeopleFill, BsFillPatchCheckFill } from "react-icons/bs";
import { WhatsAppOutlined, ShareAltOutlined } from "@ant-design/icons";
import usePermissions from "../../../Hooks/usePermissions";
import User from '../../../Hooks/Logged'
import { forwardRef } from "react";

const { Text } = Typography;
class ListaProspectos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            prospecto: {},
            prospecto_id: null,
            prospectos: {
                data: [],

                page: 1,
                limit: 20,

                total: 0,
                pages: 0,
                next:null
            }
        }
    }

    static contextType = ProyectoId;



    componentDidMount() {
        if (this.props.currentEstatus == this.props.index)
            this.getProspectos()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectAll != this.props.selectAll) {
            this.props.selectAll ? this.selectAll() : this.deselectAll()
        }
    }

    selectAll = () => {
        this.state.prospectos?.data?.forEach(prospecto => {
            !this.props.prospectosSelected[prospecto._id] && this.props.onSelectProspecto(prospecto._id)
        })
    }

    deselectAll = () => {
        this.state.prospectos?.data?.forEach(prospecto => {
            this.props.prospectosSelected[prospecto._id] && this.props.onSelectProspecto(prospecto._id)

        })
    }

    /**
     * 
     * @returns Boolean
     * @description Si los datos ya son igual al total disponible o mayor, return true
     */
    isFinal = () => {
        return this.state.prospectos?.data?.length >= this.state.prospectos?.total
    }


    /**
     * 
     * @returns Boolean
     * @description Si no se han traido datos retorna true
     */
    isEmpty = () => {
        return this.state.prospectos?.total == 0 && this.state.prospectos?.pages == 0
    }

    generateImage = (index) => ([{
        src: '/img/humanistics.png'
    }, {
        src: '/img/f_logo_RGB-Blue_512.png'
    }, {
        src: '/img/instagram.png'
    }, {
        src: '/img/humanistics.png'
    }, {
        src: <SlEarphonesAlt />,
        style: { backgroundColor: '#dadada' }
    }, {
        src: <MdSecurity />,
        style: { backgroundColor: '#f56a00' }
    }, {
        src: <BsPeopleFill />,
        style: { backgroundColor: '#87d068' }
    },
    {
        src: <WhatsAppOutlined />,
        style: { backgroundColor: '#00ff00' }
    },
    {
        src: '/img/google-ads.png'
    }])[index]

    generateName = (value) => {
        switch (value) {
            case 1:
                return "Humanistics"
            case 2:
                return "Facebook"
            case 3:
                return "Instagram"
            case 4:
                return "Formulario de contacto"
            case 5:
                return "Call Center"
            case 6:
                return "Guardia"
            case 7:
                return "Referido"

            case 8:
                return "WhatsApp"
                
            case 9:
                return "Google Ads"

            default:
                return "No asignado"

        }
    }

    getProspectos = (
        {
            page = this.state.prospectos?.page,
            limit = this.state.prospectos?.limit,
            estatus_id = this.props._id,
            asesor_id = this.props.asesor_id,
            search = this.props.search,
            filters = this.props.filters,
            visita = this.props.visita,
            probabilidad=this.props.probabilidad,
            fecha=this.props.fecha,
        } = this.state.prospectos
    ) => {
        let proyecto_id = this.context;
        this.props.setLoading(true)
        console.log('traer prospectos',page,'estatus=>',this.props._id)

        return new Promise((resolve, reject) => {
            axios.get('/prospectos', {
                params: {
                    estatus_id,
                    proyecto_id,
                    asesor_id,
                    page,
                    limit,
                    search,
                    filters,
                    visita,
                    probabilidad,
                    fecha
                }
            })
                .then(async ({ data }) => {

                    if (data?.total < data?.limit)
                        this.props.loadNext()

                    this.props.onLoadDataTotal(data.total)


                    this.setState({
                        prospectos: {
                            ...this.state.prospectos,
                            ...data,
                            data: (page == 1) ? data?.data : [...this.state.prospectos?.data, ...data?.data]
                        }
                    }, () => resolve(data))

                })
                .catch(error => {
                    console.log('error', error);
                    message.error(getResponseError(error?.response, "No se pudieron cargar los prospectos"))
                    reject(error)
                })
                .finally(f => { this.props.setLoading(false) })
        })

    }



    /**
     *
     *
     * @memberof ListaProspectos
     * @description Actualiza el registro del prospecto editado
     */
    updateProspecto = (prospecto) => {
        let prospectos = this.state.prospectos
        let index_toupdate = prospectos.data?.findIndex(p => p._id == prospecto._id);
        prospectos.data[index_toupdate] = prospecto;
        this.setState({ prospectos: { ...prospectos } })
    }



    /**
     *
     *
     * @memberof ListaProspectos
     * @description Elimina el prospecto de la lista cargada
     */
    spliceProspecto = (prospecto) => {
        let prospectos = this.state.prospectos
        let index_delete = prospectos.data?.findIndex(p => p._id == prospecto._id);
        prospectos.data?.splice(index_delete, 1)
        this.setState({ prospectos: { ...prospectos } })
    }



    /**
     *
     *
     * @memberof ListaProspectos
     * @description Añade el prospecto al estatus al que se cambio
     */
    pushProspecto = (prospecto) => {
        let prospectos = this.state.prospectos
        prospectos.data.push(prospecto)
        this.setState({ prospectos: { ...prospectos } })
    }

    getListItem = (item) => {
        const { onSelectProspecto, prospectosSelected } = this.props
        return (
            <Row align={"middle"} justify={"start"} className="width-100 " gutter={[2, 0]}>
                <Col xs={1}>
                    <Checkbox
                        disabled={!this.props.prospectos_edit && !this.props.prospectos_delete}
                        checked={prospectosSelected[item._id]}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            onSelectProspecto(item._id, this.props._id)
                        }} />
                </Col>
                <Col xs={4}>
                    <Space>
                        <Text elipsis>{item?.nombre}</Text>
                        {Array.isArray(item.citas) && item.citas.length > 0 ?
                            <BsFillPatchCheckFill style={{ color: '#A22E9B' }} />
                            : null}
                    </Space>
                </Col>
                <Col xs={4}>
                    <Text elipsis>{item?.email ?? "Sin Email"}</Text>
                </Col>
                <Col xs={4}>
                    <Text elipsis>{item?.telefono ?? "Sin Teléfono"}</Text>
                </Col>
                <Col xs={2}>
                    {generateTag(item?.probabilidad || 0)}
                </Col>
                <Col xs={1}>
                    <AntDAvatar.Group
                        maxCount={2}
                        size="small"
                        gap={1}
                        maxStyle={{
                            color: '#fff',
                            backgroundColor: 'rgb(110, 33, 175)',
                        }}
                    >
                        {item?.asignado_a?.length > 0 ? item?.asignado_a.map(asesor =>
                            <Tooltip placement="top" title={asesor?.nombre}>
                                <Avatar
                                    style={{ backgroundColor: asesor?.color }}
                                    className="card-avatar"
                                    size={30}
                                    color={asesor?.color}
                                    image={asesor?.avatar ? axios.defaults.baseURL + "/usuario/" + asesor?._id + "?avatar=true&Authorization=" + sessionStorage.getItem("token") : null}
                                    name={asesor?.nombre}
                                />
                            </Tooltip>) : null}
                    </AntDAvatar.Group>
                </Col>
                <Col xs={1}>
                    <Tooltip name={item.proyecto_id?.nombre}>
                        <Avatar
                            size={30}
                            name={item.proyecto_id?.nombre}
                            url={
                                item.proyecto_id?.logo ? {
                                    url: axios.defaults.baseURL + '/proyectos/' + item.proyecto_id?._id + "?logo=true&Authorization=" + sessionStorage.getItem("token"),
                                    name: item.proyecto_id?.logo?.name,
                                    logo: item.proyecto_id?.file
                                } : null}
                            color={item.proyecto_id?.color}
                        />
                    </Tooltip>
                </Col>
                <Col xs={2}>
                    <Text ellipsis>{moment(item?.fecha).format('DD-MMM-YYYY hh:mma')}</Text>
                </Col>
                <Col xs={3}>
                    <AntDAvatar size={32} {...this.generateImage(item.fuente - 1)} /> <span style={{ letterSpacing: '2px' }}>{this.generateName(item.fuente)}</span>
                </Col>
                <Col span={1}>
                    <Text copyable={{
                        text: window.location.href + "/" + item._id,
                        tooltips: [true, 'Link Copiado'],
                        icon: <ShareAltOutlined />
                    }}
                        title="Copiar link" ></Text>
                </Col>
            </Row>
        )
    }


    render() {


        return (
            <>
                <List
                    className="list-prospectos component-list"
                    itemLayout="horizontal"
                    bordered={false}
                    dataSource={this.state.prospectos?.data}
                    rowKey={item => item._id}
                    size="small"

                    locale={{ emptyText: 'No hay', }}
                    renderItem={(item, index) => (
                        item.ultima_cita?.estatus > 0 ? (
                            <Badge.Ribbon
                                text={
                                    item.ultima_cita?.estatus === 1 ? 'Agendado' :
                                        item.ultima_cita?.estatus === 2 ? 'Confirmado' : 'Concluido'
                                }
                                color={
                                    item.ultima_cita?.estatus === 1 ? '#faad14' :
                                        item.ultima_cita?.estatus === 2 ? '#1677ff' : '#52c41a'
                                }
                            >
                                <List.Item
                                    className="list-prospectos-item"
                                    key={item._id}
                                    onClick={() => this.props.openDrawer(item, item._id, this.props.index)}
                                >
                                    {this.getListItem(item)}
                                </List.Item>
                            </Badge.Ribbon>
                        ) : (
                            <List.Item
                                className="list-prospectos-item"
                                key={item._id}
                                onClick={() => this.props.openDrawer(item, item._id, this.props.index)}
                            >
                                {this.getListItem(item)}
                            </List.Item>
                        )

                    )}
                />
            </>
        );
    }
}

export default forwardRef((props, ref) => {
    const user = useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        prospectos_edit: ['prospectos', 'edit'],
        prospectos_delete: ['prospectos', 'delete']
    })

    return <ListaProspectos ref={ref} {...props} {...permisos} />
})