import React, { Component, useContext, useEffect, useState } from 'react';
import ReactECharts from "echarts-for-react";
import axios from 'axios'


import { Proyecto } from '../../../Hooks/Proyecto'

/**
 *
 *
 * @const ProspectosActuales
 * @extends {React.Component}
 * @description Card de la grafica circular
 */
export default function FuenteProspectos (props) {

    const { fechas } = props;
    
    let fuentes = {
        1: {text: "Humanistics", color: "#9a2a99" },
        2: {text: "Facebook", color: "#3b5998"},
        3: {text: "Instagram", color:  "#fbad50"},
        4: {text: "Formulario de contacto", color: "#ffb300"},
        5: {text: "Call Center", color: "#ffb300"},
        6: {text: "Guardia", color: "#f56a00"},
        7: {text: "Referido", color: "#87d068"},
        8: {text: "WhatsApp", color: "#00ff00"},

    }


    const proyecto = useContext(Proyecto)


    let [fuenteProspectos, setFuenteProspectos] = useState([])


    useEffect(() => {
        getFuenteProspectos()
    }, [])

    useEffect(() => {
        getFuenteProspectos()
    }, [ proyecto, fechas ])



    const getFuenteProspectos = () => {
        axios.get('/dashboard/fuente-prospectos', {
            params: {
                proyecto_id: proyecto?._id,
                fechas: fechas?.length > 0 ? [fechas[0].toDate(), fechas[1].toDate()] : null,
            }
        })
            .then(({ data }) => {
                setFuenteProspectos(data)
            })
            .catch(error => {
                console.log('errorerrorerrorerror', error)
            })

    }

    return <ReactECharts

        notMerge={true}
        lazyUpdate={true}
        style={{ maxWidth: props.maxWidth, width: "100%"}}
        option={
            {
                tooltip: {
                    trigger: 'item',
                    formatter: "{b} : {c} ({d}%)"
                },

                series: [{
                    type: "pie",
                    data: fuenteProspectos ? fuenteProspectos.map(act => {
                        return {
                            value: act.total,
                            name: fuentes[act._id].text,
                            itemStyle: {
                                color: `${fuentes[act._id].color}`
                            }
                        }
                    }) : []
                }]
            }
        }

    />
}

