import React, { Component, useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Layout } from "antd";

import Sidebar from "../Components/Sidebar/Sidebar.js";
import { User } from '../Hooks/Logged';
import Socket from '../Hooks/Socket';
import { Proyecto, SetProyecto, ProyectoId } from '../Hooks/Proyecto';

import io from "socket.io-client";
import ChatTicktes from 'iseeyoutech-widget-web/dist/iseeyoutech-widget-web.umd.js';

import RouterDashboard from "./RouterDashboard"
import ProspectosRouter from './ProspectosRoute.js';
import AsesoresRouter from './AsesoresRouter.js';
import CostosRouter from './CostosRouter.js';


import RouterSettings from "./SettingsRouter.js"
import CalendarioRouter from "./CalendarioRoutes.js"

import axios from "axios"
import OverviewRouter from "./OverviewRouter.js";
import BitacoraRouter from "./BitacoraRouter.js";


import '../Styles/Global/admin.scss'
import '../Styles/Global/chatTickets.css'

const { Content } = Layout;

class AdminRoutes extends Component {

	constructor(props) {
		super(props)
		this.state = {
			search: null,
			proyecto: null,
			socket: this.getSocket()
		}
	}

	componentDidMount() {
		this.axiosInterceptor = axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response?.status === 401)
					this.props.navigate('/login')
				return Promise.reject(error);
			}
		);
	}

	componentWillUnmount() { axios.interceptors.request.eject(this.axiosInterceptor); }


	SetFilterSearch(e) {
		this.setState({ search: e })
	}

	ref = React.createRef();

	/**
	 * @method onChangeModulo
	 * @description Limia el input de busqueda
	 * */
	onChangeModulo = () => {
		if (this.ref?.current)
			this.ref.current.clear()
	}

	setProyecto = proyecto => this.setState({ proyecto, proyecto_id: (proyecto?._id) ? proyecto?._id : null })

	getSocket = () => {
		try {

			this.state.socket?.disconnected()
		} catch (error) {
			console.log("e", error)
		}

		let socket = io.connect(process.env.REACT_APP_WEB_SERVICE, {
			extraHeaders: {
				"Authorization": sessionStorage.getItem('token')
			},

		})
		socket.io.on("open", () => {
			socket.io.engine.transport.on("pollComplete", () => {
				socket.io.opts.extraHeaders = {
					"Authorization": sessionStorage.getItem('token')
				}
			});
		})
		return socket
	}
	render() {

		return (
			<Socket.Provider value={this.state.socket}>
				<ProyectoId.Provider value={this.state.proyecto_id}>
					<Proyecto.Provider value={this.state.proyecto}>
						<SetProyecto.Provider value={this.setProyecto}>
							<Layout className="main">
								{/* <ChatTicktes
									className="chat-tickets-widget"
									isReady={true}
									URL={process.env.REACT_APP_ISY_WEB_SERVICE_CHAT}
									email="av@iseeyoutech.com"
					                nombre="Juan Alberto Virrey"
					                proyectoId="63ca032bb689ce2f94e9567c"
								/> */}
								<Sidebar />
								<Layout>
									<Content className="admin-content">
										<Routes>
											<Route path="dashboard/*" element={<RouterDashboard search={this.state.search} />} />
											<Route path="asesores/*" element={<AsesoresRouter search={this.state.search} />} />
											<Route path="prospectos/*" element={<ProspectosRouter />} />
											<Route path="overview/*" element={<OverviewRouter />} />
											<Route path='example/*' element={<ProspectosRouter />} />
											<Route path='settings/*' element={<RouterSettings />} />
											<Route path='bitacora/*' element={<BitacoraRouter />} />
											<Route path='calendario/*' element={<CalendarioRouter />} />
											<Route path='costos/*' element={<CostosRouter />} />

										</Routes>
									</Content>
								</Layout>
							</Layout>
						</SetProyecto.Provider>
					</Proyecto.Provider>
				</ProyectoId.Provider>
			</Socket.Provider>

		)
	}
}

export default function (props) {
	const user = useContext(User)
	const navigate = useNavigate()
	return <AdminRoutes {...props} user={user} navigate={navigate} />
}