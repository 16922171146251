import React from "react";
import { Mention, MentionsInput,  } from "react-mentions";
import Style from '../../../../Styles/Modules/Prospectos/menciones.css'
import Avatar from "../../../Widgets/Avatar/Avatar";
import { Space } from "antd";

const axios = require('axios')
/**
 * @class MentionsCustomized
 * @extends {React.Component}
 * 
 * @description Se ajustó el metodo de mentions para adaptarlo a ANTD y que tenga la misma funcionliadad
 */
export default class MentionsCustomized extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value: "",
            update: true,
            data: this.props.asesores.map(a => ({ id: a._id, display: a.nombre, avatar: a.avatar }))
        }
    }


    componentDidUpdate() {

        if ( this.state.value !== this.props.value) {
            this.setState({
                value: this.props.value
            })
        }

        if (this.props.asesores.length !== this.state.data.length && this.state.update) {
            this.setState({
                update:false,
                data:this.props.asesores.map(a => ({ id: a._id, display: a.nombre, avatar: a.avatar }))
            })
        }
    }

    /**
     *
     *
     * @param {*} changedValue
     * @memberof MentionsCustomized
     * 
     * @description Le dice al formulario de antd que cambió un valor interno
     */
    triggerChange = (changedValue) => this.props.onChange(changedValue)


    /**
     *
     *
     * @param {*} changedValue
     * @memberof MentionsCustomized
     * 
     * @description Se encarga de actualizar el valor en el estado. 
     */
    onChange = (ev, newValue) => {
        this.triggerChange(newValue)
        this.setState({ value: newValue });
    };



    render() {
        const { data } = this.state;
        return (
                <MentionsInput
                    value={this.state.value}
                    onChange={this.onChange}
                    placeholder="Escribe un mensaje..."
                    singleLine={false}
                    className="mention-box"
                    allowSuggestionsAboveCursor={true}>
                    <Mention
                        trigger="@"
                        markup="[__display__]{__id__}"
                        className="mention-display"
                        data={data}
                        renderSuggestion={(
                            suggestion,
                            search,
                            highlightedDisplay,
                            index,
                            focused
                        ) => (
                            <Space  className={` user ${focused ? 'focused' : ''}`} key={index} style={{padding:'5px'}}>
                                <span >{suggestion.display}</span>
                            </Space>
                        )}
                    />
                </MentionsInput>
        );
    }
}