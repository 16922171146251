import React from "react";

import { Card, Button, Typography, Row, Col } from "antd"
import '../../../Styles/Modules/Admin/Templates/MBTemplates.scss';

const { Text } = Typography;


/**
  * 
  * @param {*} header 
  * @returns {Component} Documento adjunto a la plantilla
  */
const Header = ({ header }) => {
    if (header != null) {
        switch (header.format) {
            case "document":
                return <div
                    style={{
                        height: '100px',
                        backgroundSize: 'cover',
                        backgroundImage: header.example.header_url
                    }}
                ></div>
            case "IMAGE":
                return <Card bordered={false} bodyStyle={{ display: 'none' }} cover={<img src={header.example.header_url[0]}></img>} />

            default:
                return <Text style={{ fontSize: 15, color: '#435369' }}>{header.text}</Text>
        }
    }
    return null
}


/**
 * 
 * @param {*} body 
 * @returns {Component} Body de la plantilla
 */
const Body = (body) => {
    if (body)
        return <Text style={{ fontSize: 14, color: '#435369' }}>{body.text}</Text>
    return null;
}

/**
 * 
 * @param {*} buttons 
 * @returns {Component} Botones de la plantilla
 */
const Buttons = ({ buttons }) => {

    let comp = []
    {
        buttons.map((button, index) => {
            switch (button.type) {
                case 'PHONE_NUMBER':
                    comp.push(
                        <Col span={12}>
                            <Button block key={index} type="primary" style={{ border: 'none', color: '#34B7F1', background: '#F7F7F7', boxShadow: 'rgb(195, 205, 221) 0px 1px 2px' }}>
                                {button.text}
                            </Button>
                        </Col>
                    );

                case 'URL':
                    comp.push(
                        <Col span={12}>
                            <Button block key={index} type="primary" href="#" style={{ border: 'none', color: '#34B7F1', background: '#F7F7F7', boxShadow: 'rgb(195, 205, 221) 0px 1px 2px' }}>
                                {button.text}
                            </Button>
                        </Col>
                    )
            }
        })
    }

    return <Row className="no-bg" gutter={[8, 8]}>{comp} </Row>
}
const Footer = ({ text }) => {
    return <Row ><Col span={12}><Text style={{ color: '#687992 ' }}>{text}</Text></Col></Row>;
};
/**
 *
 *
 * @returns
 */
const PreviewTemplate = React.forwardRef((props, ref) => {



    console.log('props', props)
    console.log('ref', ref)



    return (
        <Card
            bordered={false}
            className="template-card-preview"
        >
            {props.template?.components.map((component, index) => {
                switch (component.type) {
                    case 'HEADER':
                        return <Header key={index} header={component} />;
                    case 'BODY':
                        return <Body key={index} text={component.text} />;
                    case 'FOOTER':
                        return <Footer key={index} text={component.text} />;
                    case 'BUTTONS':
                        return <Buttons key={index} buttons={component.buttons} />;
                    default:
                        return null;
                }
            })}
        </Card>
    );
})

export default PreviewTemplate