

import React, { useEffect, useState, useRef } from "react";
import { Comment, Typography, Button, Space, Empty, Tag } from "antd"
import axios from "axios";
import { Component } from "react";
const moment = require('moment-timezone');


const { Text } = Typography

/**
 *
 *
 * @param {*} props
 * @returns {Component}
 */
function MessagesList(props) {
    const { data, className, proyecto } = props;

    /**@isOverflowed  si el contenedor esta desbordado*/
    const [isOverflowed, setIsOverflowed] = useState(false);

    /**@chatContainer referencia al contenedor del chat */
    const chatContainer = useRef(null);

    /**@timezone Zona horaria */
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);


    /**
     * @function scrollToBottom
     * @description Hace scroll cuando el contenedor recibe un nuevo elemento
     */
    function scrollToBottom() {
        chatContainer.current.scrollTo({
            top: chatContainer.current.scrollHeight,
            behavior: "smooth"
        });
    }

    /**Asigna a la bandera si el contenedor esta desbordad */
    useEffect(() => {
        setIsOverflowed(chatContainer.current.scrollHeight > chatContainer.current.clientHeight);
    }, []);

    /**Scrollea hacia abajo al montarse el componente */
    useEffect(() => {
        scrollToBottom()
    },);



    /**
     * @function printHour
     * @description Muestra la hora en un formato especifico */
    const printHour = (date) => {
        let hora = moment(date)
        try {
            return hora.tz(timezone).format('LLL')
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * 
     * @param {*} header 
     * @returns {Component} Documento adjunto a la plantilla
     */
    const renderHeader = (header) => {
        if (header != null) {
            switch (header.type) {
                case "document":
                    return <div
                        style={{
                            height: '100px',
                            backgroundSize: 'cover',
                            backgroundImage: `${axios.defaults.baseURL}/proyectos/${proyecto?._id}?logo=${proyecto?.logo?.file}&Authorization=${sessionStorage.getItem("token")}`
                        }}
                    ></div>
                    break;
            }
        }
        return null
    }


    /**
     * 
     * @param {*} body 
     * @returns {Component} Body de la plantilla
     */
    const renderBody = (body) => {
        if (body)
            return <span>{body.text}</span>

        return null;
    }


    /**
     * 
     * @param {*} reply 
     * @returns {Component} Respuesta de la plantilla
     */
    const renderReply = (reply) => {
        if (reply)
            return <span>{reply.text}</span>

        return null;
    }

    /**
     * 
     * @param {*} buttons 
     * @returns {Component} Botones de la plantilla
     */
    const renderButtons = (buttons) => {

        let components = []
        if (buttons && buttons.length > 0) {
            buttons.map(btn => {
                components.push(<Button disabled type="link" size="small" id={btn.id} tipo={btn.type}>{btn.title}</Button>)
            });
        }

        return <Space direction="horizontal">{components}</Space>
    }
    return <div className="lista-messages" ref={chatContainer} >
        {
            data.length > 0 ? data.map(item => {
                return <Comment
                    key={item?._id}
                    className={`width-100 ${className} ${(item?.direction) == "sent" ? `right` : `left`}`}
                    datetime={<span className="flex-end">{printHour(item.createdDatetime)}</span>}

                    content={
                        <>
                            <div className={'message-content'}>
                                <span className={item.status}>
                                    {(item?.content?.text ? item.content.text : null)}
                                    {(item?.content?.interactive ?
                                        <div className="message-interactive">
                                            {renderHeader(item.content.interactive.header)}
                                            {renderBody(item.content.interactive.body)}
                                            {renderButtons(item.content.interactive.action?.buttons)}
                                            {renderReply(item.content.interactive.reply)}
                                        </div>
                                        : null
                                    )}
                                    {(item?.content?.hsm ?
                                        <span>Se envio template <Text strong>{item.content.hsm.templateName}</Text></span>
                                        : null
                                    )}

                                </span>
                                <div className="message-error">
                                    <span className={item.status}>{item.error?.description}</span>
                                </div>
                            </div>
                        </>
                    }
                >
                </Comment>
            }
            ) : <Empty />
        }
    </div >
}


export default MessagesList;