import { useEffect, useState } from "react"

const Chart = ({parentRef, children}) => {
    const [max, setMax] = useState(undefined)
    
    useEffect(() => {
        let width = parentRef?.current?.clientWidth
        setMax(width)
    }, [])

    if(!max) return null
    return children(max)
}

export default Chart