import React, { Component } from "react";
import { Modal, Spin, Form, Button, message, Input, Rate, Radio } from 'antd';

import axios from "axios"
import { getResponseError } from "../../../Utils";
import {
    SmileOutlined, MehOutlined, FrownOutlined, 
    CheckOutlined,
    CloseOutlined
} from "@ant-design/icons"

import moment from 'moment-timezone'
import '../../../../Styles/Modules/Prospectos/modales.scss'

const customIcons = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />,
};
const { TextArea } = Input;
moment.tz.setDefault("America/Mexico_City")

/**
 *
 *
 * @export
 * @class FormFeedBack
 * @extends {Component}
 */
class FormFeedBack extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            visito: false
        }

    }

    formRef = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    onChangeVisito = (value) => {
        console.log('visito', value)
        this.setState({ visito: value })
    }

    /**
    *
    * @methodOf FormVisita
    *
    * @function addSeguimiento
    * @description Añade una cita
    * */
    addSeguimiento = (values) => {
        this.setState({ loading: true })
        axios.put('/citas/feedback', {
            prospecto_id: this.props.prospecto_id,
            id: this.props.cita._id,
            ...values
        })
            .then(({ data }) => {
                message.success("FeedBack Guardado")
                this.props.onClose()
            })
            .catch(error => {
                message.error(getResponseError(error.response, "No es posible guardar el feedback."))
            })
            .finally(() => this.setState({ loading: false }))

    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    onFinish={this.addSeguimiento}
                    ref={this.formRef}
                    initialValues={{}}
                >
                    <Form.Item
                        label="¿Asistió el prospecto?"
                        name="asistio"
                        className="inline-form-item"
                        rules={[{
                            required: true,
                            message: 'Por favor seleccione una opción'
                        }]}
                    >
                        <Radio.Group size="large" className="radio-icons " onChange={(e) => this.onChangeVisito(e.target.value)}>
                            <Radio.Button value={true}><CheckOutlined style={{ color: '#00FF00' }} /></Radio.Button>
                            <Radio.Button value={false}><CloseOutlined style={{ color: '#FF0000' }} /></Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="FeedBack"
                        name="feedback"
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    {this.state.visito ?
                        <Form.Item
                            label="¿Cómo calificarías la visita?"
                            name="valoracion"
                            rules={[{
                                required: true,
                                message: 'Por favor asigne una calificación'
                            }]}
                        >
                            <Rate className="custom-rate"
                                character={({ index }) => customIcons[index + 1]}

                            />
                        </Form.Item>
                        : null}
                    <Form.Item className="center">
                        <Button type="primary" htmlType="submit" >
                            GUARDAR
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>

        );
    }

};

export default function (props) {


    return <Modal
        open={props.visible}
        onCancel={props.onClose}
        closable={true}
        footer={false}
        destroyOnClose={true}
        width={400}
        style={{
            background: '#EEE9F5'
        }}
        title={<>
            <span className="center">Dar Seguimiento</span>
            <span className="center text-gray">Cita : {moment(props.cita?.fecha).format('DD/MM/YYYY HH:mm')}</span>
            <span className="center text-gray"> {props.cita?.descripcion ?? ""}</span>
        </>}
    >
        <FormFeedBack {...props} />
    </Modal >
}


