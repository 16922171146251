import React, { useContext, useEffect, useState } from 'react';
import ReactECharts from "echarts-for-react";
import axios from 'axios'


import { Proyecto } from '../../../Hooks/Proyecto'

/**
 *
 *
 * @const ProspectosActuales
 * @extends {React.Component}
 * @description Card de la grafica circular
 */
export default function ProspectosMesChart(props) {
    let now = new Date()
    const { fechas } = props;

    



    const proyecto = useContext(Proyecto)

    let [data, setData] = useState({
    	usuarios: [],
    	prospectos: []
    })

    useEffect(() => {
        getProspectosActuales()
    }, [proyecto, fechas])


    const getProspectosActuales = () => {
        axios.get('/dashboard/prospectos/vendedores', {
            params: {
                proyecto_id: proyecto?._id,
                fechas: fechas?.length > 0 ? [fechas[0].toDate(), fechas[1].toDate()] : null,
                perro: true
            }
        })
            .then(({ data }) => {
            	console.clear()
                console.log('prospectos', data)
                setData(data)
            })
            .catch(error => {
                console.log('error', error)
            })

    }

    let option = {
        xAxis: {
            type: 'category',
            data: data.usuarios.map(m => `${m.nombre}`),
            axisLabel: {
	            rotate: 30  // Cambia el valor según el ángulo deseado, por ejemplo, 45 grados
	        }
        },
        tooltip: {
            trigger: 'item',
            formatter: proyecto ? "{b} : {c}" : "{a}: {c}"
        },
        yAxis: {
            type: 'value'
        },
        series: data.prospectos.map(({ data_chart, color, nombre }) => ({
            data: data.usuarios.map((usuario, i) => {
                return  data_chart.usuario_id === usuario._id ? data_chart.cantidad : 0 
            }),
            type: 'bar',
            name: nombre,
            stack: 'Proyectos',
            itemStyle: { color }
        }))
    }

    return <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        style={{ maxWidth: props.maxWidth, width: "100%" }}
        option={option}

    />
}