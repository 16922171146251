import React from 'react'
import { Route, Routes } from "react-router-dom";
import Prospectos from '../Components/Admin/Prospectos/Prospectos';
import ProspectosBoard from '../Components/Admin/Prospectos/ProspectosBoard';

/**
 * 
 * @export
 * @function RouterProspectos
 * @description Router for Prospectos routes 
 */
function RouterProspectos(props) {
  return (
    <Routes>
      <Route path='/' element={<Prospectos />}/>
      <Route path='/:prospecto_id' element={<Prospectos />}/>
      <Route path='/asesor/:asesor_id' element={<Prospectos />}/>
      <Route path='/board' element={<ProspectosBoard />}/>
    </Routes>
  )
}

export default RouterProspectos