import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from 'antd';

// Components
import ThemeEdit from '../Components/ThemeEdit/index.js';
import Login from '../Components/Auth/Login'
import Recovery from '../Components/Auth/Recovery';
import UpdatePassword from '../Components/Auth/UpdatePassword'
import FormularioContacto from '../Components/Public/FormularioContacto';


/**
 * 
 * @export
 * @function PublicRoutes
 * @description Router for handling all public routes
 */
function PublicRoutes(props) {

  return (
    <Layout>
      <Routes>
        <Route path='/' element={<Navigate to="/login" />} />
        <Route path='login/:back?' element={<Login {...props}/>} />
        <Route path='password/recovery' element={<Recovery />} />
        <Route path='recovery/:token' element={<UpdatePassword />} />
        <Route path='register/:token' element={<UpdatePassword />} />
        <Route path='theme' element={<ThemeEdit />} />
        <Route exact path="/contacto/:proyecto_id" element={<FormularioContacto/>} />
      </Routes>
    </Layout>
  )
}

export default PublicRoutes;