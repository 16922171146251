
import React, { useContext, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Rate,
    Input,
    Modal,
    message,
    Tabs,
    Divider,
    Button,
    InputNumber,
    Checkbox,
    Avatar,
    Spin,
    Radio,
    Tag,
    Drawer,
    Space,
    List,
    Form,
    Select,
    DatePicker,
    Typography,
    Card
} from 'antd';
import axios from 'axios';

import Actualizaciones from "./Actualizaciones/Actualizaciones"
import Chat from './Chat';

import { BsMessenger, BsPeopleFill, BsWhatsapp } from "react-icons/bs";
import { SlEarphonesAlt } from "react-icons/sl";
import { MdSecurity } from "react-icons/md"
import { SmileOutlined, UserOutlined, ExclamationCircleOutlined, MailOutlined, MehOutlined, FrownOutlined, MessageOutlined, RedoOutlined, WhatsAppOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';
import { IconEmailOpen, IconWhatsApp, IconPhone, MaxMin } from "../../Widgets/Iconos";

import Logged from '../../../Hooks/Logged';
import usePermissions from '../../../Hooks/usePermissions'
import { Proyecto, ProyectoId } from '../../../Hooks/Proyecto'
import CustomAvatar from "../../Widgets/Avatar/Avatar"
import { getResponseError, generateTag } from '../../Utils';

// import '../../../Styles/Modules/Prospectos/drawer.css'
import '../../../Styles/Modules/Prospectos/Email.scss'

const { Option } = Select
const { Text, Paragraph } = Typography;
const moment = require('moment');

function EmailSendForm(props) {

    const { prospecto_id } = props

    const [correos, setCorreos] = useState({
        data: [],

        page: 1,
        limit: 20,

        total: 0,
        pages: 0
    })

    const [plantillas, setPlantillas] = useState({
        data: [],

        page: 1,
        limit: 20,

        total: 0,
        pages: 0
    })

    useEffect(e => {
        getPlantillas()
        getCorreos()
    }, [])

    const getCorreos = ({ page, limit, search } = correos) => {
        axios.get('/correos', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            setCorreos({ ...correos, ...data })
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener los correos")
        }).finally(() => this.setState({ loading: false }))
    }

    const getPlantillas = ({ page, limit, search } = plantillas) => {
        return axios.get('/email-templates', {
            params: {
                page, limit, search
            }
        })
            .then(({ data }) => {
                setPlantillas({ ...plantillas, ...data })
            })
            .catch(({ response, ...args }) => {
                console.error({ ...response, ...args });
                if (response.data)
                    message.error(response.data)
            })
    }

    const handleSubmit = values => {
        values.email_id = values?.email_id?.value || values?.email_id
        values.email_template_id = values?.email_template_id?.value || values?.email_template_id
        axios.post('/email-templates/send', {
            ...values,
            prospecto_id
        })


    }

    return <Form
        onFinish={handleSubmit}
        style={{ width: "100%", paddingTop: '1em' }} >
        <Row gutter={[12, 0]} >
            <Col style={{ flex: 1 }}>
                <Form.Item
                    name="email_id"
                    rules={[{
                        required: true,
                        message: 'Por favor seleccione el estatus'
                    }]}
                >
                    <Select
                        placeholder="Correo Electrónico"
                        allowClear
                        showSearch
                        labelInValue
                        // mode="multiple"
                        // disabled={user?.rol_id?.tipo === 2 && this.props.prospecto_id}
                        // onSelect={(e) => (this.props.prospecto_id) ? this.onChangeForm({ asignado_a: e.value }) : null}
                        // onDeselect={(e) => (this.props.prospecto_id) ? this.onChangeForm({ desasignado_a: e.value }) : null}
                        filterOption={false}
                        onSearch={(search) => getCorreos({ search, page: 1 })}
                    >
                        {correos?.data.map(e => <Option value={e._id} key={e._id}>
                            {e.nombre}
                        </Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col style={{ flex: 2 }}>
                <Form.Item
                    name="email_template_id"
                    rules={[{
                        required: true,
                        message: 'Por favor seleccione el estatus'
                    }]}
                >
                    <Select
                        placeholder="Plantilla de Correo"
                        allowClear
                        showSearch
                        labelInValue
                        filterOption={false}
                        onSearch={(search) => getPlantillas({ search, page: 1 })}
                    >
                        {plantillas?.data.map(e => <Option value={e._id} key={e._id}>
                            {e.nombre}
                        </Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col>
                <Form.Item>
                    <Button htmlType='submit' icon={<SendOutlined style={{ color: "currentcolor" }} />} />
                </Form.Item>
            </Col>
        </Row>
    </Form>


}

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
export default class Emails extends React.Component {

    constructor(props) {
        super(props)

        this.parentRef = React.createRef()
        this.state = {
            loading: false,
            height: 0,


            historial: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateSize);
        this.updateSize()
        this.getHistorial()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSize);
    }

    updateSize = () => {
        if (this.parentRef.current)
            this.setState({ height: this.parentRef.current.offsetHeight, })
    }

    getHistorial = (
        {
            page = this.state.historial.page,
            limit = this.state.historial.limit,
        } = this.state.historial
    ) => {
        axios.get('/emails/history', {
            params: {
                page,
                limit,
            }
        })
            .then(({ data }) => {
                this.setState({
                    historial: {
                        ...this.state.historial,
                        ...data
                    }
                })
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al obtener los correos")
            })
            .finally(() => this.setState({ loading: false }))
    }


    renderHistory = (item) => {

        let title = [
            'Se envió correo' 
        ]

        if (item?.email_template_id) {
            title.push(
                ' ',<strong>{item?.email_template_id?.nombre} </strong>, ' ',
            )
        }


        if (item?.automatizacion_id) {
            title.push(
                ' por automatización ', <strong>{item?.automatizacion_id?.nombre}</strong>
            )
        } else {
            title.push(
                ' enviado por ', <strong>{item?.usuarios_id?.nombre}</strong>
            )
        }

        if (item?.email_id) {
            title.push(
                ' con la cuenta ', <strong>{item?.email_id?.nombre}</strong>
            )
        }


        
        return <List.Item>
            <Card style={{ width: '100%' }}>
                <Row style={{ justifyContent: "space-between" }}>
                    <Text>{title}</Text>
                    <Space style={{ width: "100%"}} align='end'>
                        <Tag>{moment(item?.createdAt).format('LLL')}</Tag>
                    </Space>
                </Row>
            </Card>
        </List.Item>
    }

    render() {

        return (
            <Row style={{ width: "100%", height: '100%', display: "flex", flexDirection: "column" }}>
                <div
                    ref={this.parentRef}
                    style={{
                        width: "100%",
                        flex: 1,
                    }}
                >
                    <List
                        className='list-items'
                        style={{
                            width: "100%",
                            overflowY: "scroll",
                            height: this.state.height
                        }}
                        dataSource={this.state.historial.data}


                        renderItem={this.renderHistory}
                    />
                </div>
                <EmailSendForm prospecto_id={this.props.prospecto_id} />
            </Row>
        )
    }
}