import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Modal, Menu } from 'antd'
import axios from 'axios';

//componentes
import Logged from "../../../Hooks/Logged";
import usePermissions from '../../../Hooks/usePermissions';
import Avatar from "../../Widgets/Avatar/Avatar";


//modal
import HeaderAdmin from '../../Header/HeaderAdmin'
import { getResponseError } from '../../Utils';
import moment from 'moment';

const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Bitacora
 * @extends {Component}
 * @description Vista donde se listan todos los bitacora
 */
class Bitacora extends Component {
    static contextType = Logged;



    /**
     *Creates an instance of Bitacora.
     * @param {*} props
     * @memberof Bitacora
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            bitacora: {
                data: [],
                limit: 20,
                page: 1,

                total: 0,
                pages: 0,

            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getBitacora()
    }



    /**
     *
     * @memberof Bitacora
     * @method getBitacora
     * @description Obtiene la informacion de los Bitacora y actualiza los valores locales de la lista.
     */
    getBitacora = async ({
        page = this.state.bitacora.page,
        limit = this.state.bitacora.limit
    } = this.state.bitacora) => {

        this.setState({ loading: true, bitacora: { ...this.state.bitacora, page, limit } })

        axios.get('/bitacora', {
            params: { limit, page }
        })
            .then(({ data }) => {
                this.setState({ bitacora: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }



    render() {


        return (
            <>
                <Spin spinning={false}>
                    <Content className='content'>
                        <Card size='small' className='card-header'>
                            <PageHeader
                                title="Bitacora"
                            />
                        </Card>
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Bitacora" }}
                            dataSource={this.state.bitacora.data}
                            pagination={{
                                current: this.state.bitacora.page,
                                pageSize: this.state.bitacora.limit,
                                total: this.state.bitacora.total,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                onChange: (page, limit) => this.getBitacora({ page, limit })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                {/* <Col span={4} className="center">
                                    <Text strong>Acción</Text>
                                </Col> */}

                                <Col span={14} className="center">
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Usuario</Text>
                                </Col>
                                <Col span={5} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            {/* <Col span={4} className="center">
                                                <Text>{item.accion}</Text>
                                            </Col> */}
                                            <Col span={14} className="center">
                                                <Text>{item.descripcion}</Text>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Space>
                                                    <Avatar
                                                        size={35}
                                                        color={item.usuario?.color}
                                                        name={item.usuario?.nombre}
                                                        url={item.usuario?.avatar ? {
                                                            url: axios.defaults.baseURL + '/usuarios/'+ item.usuario?._id,
                                                            avatar: item.usuario?.avatar?.name
                                                        } : null	}
                                                    />
                                                    <Text>{item.usuario?.nombre}</Text>
                                                </Space>
                                            </Col>
                                            <Col span={5} className="center">
                                                <Text>{moment(item.createdAt).format("DD/MM/YYYY HH:mm")}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                </Spin>
            </>
        )
    }
}

export default function (props) {


    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {})

    return <Bitacora {...props} {...permissions} />
}