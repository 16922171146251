import React, { useContext, } from 'react';
import { Col, Row, Carousel, message, Tabs } from 'antd';
import axios from "axios";
import { LeftOutlined, RightOutlined, } from '@ant-design/icons';
import Avatar from "../Widgets/Avatar/Avatar"
import { Proyecto, SetProyecto } from "../../Hooks/Proyecto";
import '../../Styles/Modules/Admin/Sidebar.scss'

const { TabPane } = Tabs;

class CarouselProyecto extends React.Component {

    state = {
        selectedProject: null,
        loading: true,
        proyectos: {
            data: [],
            page: 1,
            limit: 20,
            total: 0,
        },
        activeKey: "humanistics-keys"
    }


    /**
     *
     *
     * @memberof Sidebar
     * @method componentDidMount
     * 
     * @description Obtenemos todos los proyectos y lo declaramos en la vista
     * 
     */
    componentDidMount() {
        this.setState({
            currentCarousel: this.currentCarousel,
            selectionCarousel: this.selectionCarousel
        });
        this.getProyectos()
    }


    /**
    * @methodOf  Settings
    * @function getProyectos
    *
    * @description Consulta lista de proyectos
    *
    * */
    getProyectos = async ({ page = 1, limit = 100 } = this.state.proyectos) => {
        this.setState(state => {
            state.loading = true
            state.proyectos.page = page
            state.proyectos.limit = limit
            return state;
        })

        this.onSelected(null)
        axios.get('/proyectos', {
            params: { page, limit }
        })
            .then(({ data }) => {
                console.log('data', data);
                this.setState({
                    proyectos: {
                        ...this.state.proyectos,
                        ...data,
                        loading: false
                    }
                })
            })
            .catch((error) => {
                message.error("No fue posible cargar los proyectos.");
            })
        // .finally(() => this.props.setLoading(false))
    }

    /**
     *
     *
     * @memberof Sidebar
     * @method carouselNext
     * 
     * @description Avanzamos un elemento del sidebar 
     * 
     */
    carouselNext = () => this.selectionCarousel.innerSlider.slickNext()

    /**
     *
     *
     * @memberof Sidebar
     * @method carouselPrev
     * 
     * @description Retrocedemos un elemento del sidebar 
     * 
     */
    carouselPrev = () => this.selectionCarousel.innerSlider.slickPrev()


    /**
     * 
     * @param {*} proyectos 
     * @returns 
     * 
     * @method onSelected
     * @description Actualizamos el proyecto seleccionado. 
     * */
    onSelected = async (selectedProject) => {

        this.setState({
            selectedProject,
            activeKey: (selectedProject?._id) ?
                `project-tabkey-${selectedProject._id}`
                :
                'project-tabkey-humanistics'
        })
        this.props.setProyecto(selectedProject?._id ? selectedProject : null)
    }


    /**
     * 
     * @param {*} proyectos 
     * @returns 
     * 
     * @method renderTabBar
     * @description Renderizamos el carousel del tabbar
     * */
    renderTabBar = (props) => (
        (this.state.proyectos.data.length < 3) ?
            <Row className='panes' align="middle">
                {props.panes.map(pane => {
                // console.log('pane.props', pane);
                const { proyecto } = pane.props
                let { nombre, _id, logo, color } = proyecto

                return <a  key={(_id) ? _id : 'humanistics'} onClick={() => this.onSelected(proyecto)} className="selProyecto">
                    <div
                        className='avatar-carousel-container'
                        // style={{ backgroundColor: (pane.key === this.state.activeKey) ? '#9E2E9D' : undefined }}>
                        style={{
                            backgroundColor: (this.state.selectedProject === null && proyecto._id == null) ? "#9b2d9e" :
                                (proyecto._id === this.state.selectedProject?._id) ?
                                    (proyecto.color !== undefined) ? proyecto.color : "#9E2E9D"
                                    : 'transparent'
                        }}
                    >
                        <Avatar
                            className="avatar-carousel"
                            shape="square"
                            name={nombre}
                            color={color}
                            url={proyecto.logo ? {
                                url: axios.defaults.baseURL + '/proyectos/' + proyecto._id,
                                logo: proyecto.logo?.name
                            } : null}
                            style={{
                                backgroundColor: (proyecto.color === undefined) ? '#9E2E9D' : proyecto.color
                            }}
                        />
                    </div>
                </a>
            })}
            </Row>
            :
            <Row style={{ width: '100%', height: '46px', overflow: 'hidden' }}>
                <Col span={2} style={{ zIndex: 2 }}>
                    <a className="button-arrow button-arrow-left" onClick={this.carouselPrev}><LeftOutlined /></a>
                </Col>
                <Col span={20}>
                    <Carousel
                        asNavFor={this.state.currentCarousel}
                        ref={ref => this.selectionCarousel = ref}
                        dots={false}
                        arrows={false}
                        // rows={1} 
                        slidesToShow={4}
                        // slide
                        focusOnSelect={true}
                        // dir="ltr"
                        // rtl
                        // rtl
                        prefixCls="carousel-prefix"
                        defaultSelectedKeys={"0"}
                    >
                        {props.panes.map(pane => {
                            // console.log('pane.props', pane);
                            const { proyecto } = pane.props
                            let { nombre, _id, logo, color } = proyecto

                            return <a key={(_id) ? _id : 'humanistics'} onClick={() => this.onSelected(proyecto)} className="selProyecto">
                                <div
                                    className='avatar-carousel-container'
                                    // style={{ backgroundColor: (pane.key === this.state.activeKey) ? '#9E2E9D' : undefined }}>
                                    style={{
                                        backgroundColor: (this.state.selectedProject === null && proyecto._id == null) ? "#9b2d9e" :
                                            (proyecto._id === this.state.selectedProject?._id) ?
                                                (proyecto.color !== undefined) ? proyecto.color : "#9E2E9D"
                                                : 'transparent'
                                    }}
                                >
                                    <Avatar
                                        className="avatar-carousel"
                                        shape="square"
                                        name={nombre}
                                        color={color}
                                        url={proyecto.logo ? {
                                            url: axios.defaults.baseURL + '/proyectos/' + proyecto._id,
                                            logo: proyecto.logo?.name
                                        } : null}
                                        style={{
                                            backgroundColor: (proyecto.color === undefined) ? '#9E2E9D' : proyecto.color
                                        }}
                                    />
                                </div>
                            </a>
                        })}

                    </Carousel>
                </Col>
                <Col span={2}>
                    <a className="button-arrow button-arrow-right" onClick={this.carouselNext}><RightOutlined /></a>
                </Col>
            </Row>
    )


    /**
     * 
     * @param {*} proyectos 
     * @returns 
     * 
     * @method renderProjectsName
     * @description Declaramos el proyecto de tabs en el render
     */
    renderProjectsName = (proyectos = this.state.proyectos.data) => {

        //Agregamos el proyecto de humanistics en el tabpane
        let humanisticsTabKey = [
            <TabPane
                tab={"Humanistics"}
                key="project-tabkey-humanistics"
                proyecto={{
                    _id: null,
                    nombre: "Humanistics",
                    logo: "/img/opc-1.png",
                    color: "#6e21af",
                }}>
                <div className="selection-carousel">
                    <h3 className="title-hm-project">Humanistics</h3>
                </div>
            </TabPane>
        ]

        //Agregamos el resto de proyectos al tabbane
        for (const proyecto of proyectos) {
            const { nombre, _id, logo, color } = proyecto;
            humanisticsTabKey.push(<TabPane tab={nombre} key={`project-tabkey-${_id}`} proyecto={proyecto}>
                <div key={_id} className="selection-carousel" style={{ background: color }}>
                    <h3 className="title-hm-project" style={{ background: color }} >{nombre}</h3>
                </div>
            </TabPane>)
        }
        return humanisticsTabKey
    }


    render() {
        return <Tabs
            activeKey={this.state.activeKey}
            type="card"
            renderTabBar={this.renderTabBar}>
            {this.renderProjectsName()}
        </Tabs>
    }
}

export default function SidebarWrapper(props) {

    const proyecto = useContext(Proyecto)
    const setProyecto = useContext(SetProyecto)

    return <CarouselProyecto {...props} proyecto={proyecto} setProyecto={setProyecto} />
}
