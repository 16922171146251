import React, { Component } from "react";
import { Row, Col, Typography, Button, Layout, Popconfirm, Empty, Space, message, List, Card, Modal } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined, } from "@ant-design/icons";
import Logged from '../../../Hooks/Logged'
import usePermissions from "../../../Hooks/usePermissions";


import HeaderAdmin from "../../Header/HeaderAdmin"
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import { getResponseError } from "../../Utils";


const { Content } = Layout
const { Text, Title } = Typography
/**
 *
 *
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista donde se listan todos los proyectos
 */
class MBTemplates extends Component {


    /**
     *Creates an instance of MBTemplates.
     * @param {*} props
     * @memberof MBTemplates
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible_modal: false,
            templates: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },
        };
    }

    componentDidMount() {
        this.get();
    }


    get = (
        {
            page = this.state.templates.page,
            limit = this.state.templates.limit,
        } = this.state.templates
    ) => {
        this.setState({ loading: true })
        return axios.get('/mb-templates', {
            params: {
                page, limit
            }
        })
            .then(({ data }) => {
                console.log('data templates', data)
                this.setState(state => {
                    state.templates.data = data.items
                    state.templates.total = data.totalCount
                    return state;
                })
            })
            .catch(error => {
                console.log('error templates', error)
                getResponseError(error.response, 'Ocurrio un error al cargar los templates')
            })
            .finally(() => this.setState({ loading: false }))
    }


    delete = (name) => {
        return Modal.confirm({
            title: "¿Desea eliminar el template?",
            content: "Se eliminará el template completamente. No se podrá recuperar de ningun modo",
            onOk: () => axios.delete('/mb-templates/' + name)
                .then((response) => {
                    this.get()
                })
                .catch((error) => {
                    message.error(error?.response?.data ?? "No fue posible eliminar")
                })
        })
    }


    render() {
        return (
            <>
                <Content className='content'>
                    <HeaderAdmin
                        title="Templates de MessageBird"
                    />
                    <List
                        className="component-list "
                        itemLayout="horizontal"
                        locale={{ emptyText: <Empty description="Sin Templates" /> }}
                        dataSource={this.state.templates.data}
                        pagination={{
                            current: this.state.templates.page,
                            pageSize: this.state.templates.limit,
                            total: this.state.templates.total,
                            hideOnSinglePage: false,
                            position: "bottom",
                            showSizeChanger: false,
                            onChange: (page, limit) => this.get({ page, limit }),
                        }}
                        renderItem={(item) => (
                            <List.Item className="component-list-item" key={item._id}>
                                <Card className="card-list">
                                    <Row gutter={[16, 8]}>
                                        <Col span={6} className="center" >
                                            <Text >{item.name}</Text>
                                        </Col>
                                        <Col span={6} className="center">
                                            <Text className="text-gray" >{item.language}</Text>
                                        </Col>
                                        <Col span={4} className="center">
                                            <Text className="text-gray" >{item.status}</Text>
                                        </Col>
                                        <Col span={6} className="center">
                                            <Text className="text-gray" copyable >{item.namespace}</Text>
                                        </Col>
                                        <Col span={2} className="center">
                                            <Space>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar este Correo?"
                                                    onConfirm={() => this.delete(item.name)}
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="link"
                                                        danger
                                                        title="Eliminar"
                                                        icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
                                                    />
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                </Content >
                <FloatingButton
                    onClick={() => this.props.navigate('/admin/settings/mb-templates/crear')}
                />
            </>
        );
    }
}

export default function MBTemplatesWrapper(props) {

    let user = React.useContext(Logged)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        createProyectos: ["mb-templates", "create"],
        editProyectos: ["mb-templates", "edit"],
        readProyectos: ["mb-templates", "read"],
        deleteProyectos: ["mb-templates", "delete"],
    })

    return <MBTemplates {...props} {...permissions} navigate={useNavigate()} />
}