import React, { Component, useContext, useState, useEffect } from 'react';
import { Button, message, Row, Col, Form, Space, Select, Modal } from 'antd'
import { UserOutlined, CheckOutlined } from '@ant-design/icons';
import { IconCloseModal, IconCheck } from '../Iconos'

import Avatar from '../Avatar/Avatar'
import './BtnProspectos.css'
import axios from "axios";
const { Option } = Select;
class FormAsesores extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            asesores: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                search: null,
            }
        }
    }


    componentDidMount() {
        this.getAsesores()
    }
    valForm = React.createRef();

    /**
    *
    *
    * @memberof ProspectoModal
    * @description Obtiene la lista de estatus del prospecto
    * 
    * @param proyecto_id ObjectId del proyecto actual 
    */
    updateProspecto = (values) => {

        let asesores_ids = []
        values.asesor_id.forEach(asesor => {
            asesores_ids.push(((asesor.value != undefined) ? asesor.value : asesor))

        });

        if (this.props.prospectos.length === 0) {
            message.error('No hay prospcetos seleccionados')
        } else {
            axios.post('/prospectos/update/many', {
                proyecto_id: this.props.proyecto,
                prospectos: this.props.prospectos,
                asesor_id: values.asesor_id,
            }).then(({ data }) => {
                message.success('Prospectos Actualizados')
                this.props.onCancel()
                this.props.updateProspectos(data.data)
                setTimeout(() => {
                    this.valForm.current.resetFields();
                }, 250);

            }).catch(error => {
                console.log(error)
                message.error('Error al actualizar')
            })
        }
    }


    getAsesores = ({ page, limit, search } = this.state.asesores) => {

        axios.get('/asesores', {
            params: {
                page,
                limit,
                search,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            console.log('data asesores', data)
            this.setState({ asesores: data })
        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los asesores')
        })
    }
    render() {


        return (
            <Form layout={"vertical"} requiredMark={false} ref={this.valForm} onFinish={this.updateProspecto}>

                <Form.Item
                    name="asesor_id"
                    rules={[{
                        required: true,
                        message: 'Por favor seleccione el Asesor'
                    }]}
                    shouldUpdate={true}
                >
                    <Select
                        placeholder="Asesor"
                        allowClear
                        showSearch
                        filterOption={false}
                        mode="multiple"
                        onSearch={(search) => this.getAsesores({ search, page: 1 })}
                    >
                        {this.state.asesores?.data.map(e => <Option value={e._id} key={e._id}>
                            <Space size="middle">
                                <Avatar
                                    style={{ marginRight: 10, backgroundColor: e?.color }}
                                    color={e?.color}
                                    image={e?.avatar ? axios.defaults.baseURL + "/usuario/" + e?._id + "?avatar=true&Authorization=" + sessionStorage.getItem('token') : null}
                                    name={e?.nombre}
                                    size='small'
                                />
                                {e.nombre}
                            </Space>
                        </Option>)}
                    </Select>

                </Form.Item>
                <Form.Item ><Button block type='primary' icon={<CheckOutlined />} htmlType="submit">Asignar</Button></Form.Item>
            </Form >
        )
    }

}
export default function AsesorModal(props) {

    return <Modal
        open={props.visible}
        onCancel={props.onCancel}
        closable={true}
        closeIcon={<div> <IconCloseModal /> </div>}
        footer={false}
        destroyOnClose={true}
        mask={true}
        centered
        width={400}
        maskClosable={true}
        title="Asignar Asesores"

    >
        <FormAsesores {...props} />
    </Modal >

}





