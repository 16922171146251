import React, { createRef, useEffect, useState } from 'react'
import { message, Card, } from "antd";
import { FaFacebook } from "react-icons/fa";
import { IconBag, IconMoneyRecive, IconWalletMoney } from '../../Widgets/Iconos';
import axios from 'axios'
import { useContext } from 'react';
import { Proyecto } from '../../../Hooks/Proyecto'
import Analytics from './Analytics';
import { useNavigate } from "react-router-dom";


import '../../../Styles/Modules/Admin/Overview.scss'

/**
 *
 *
 * @const ProspectosActuales
 * @extends {React.Component}
 * @description Card de la grafica circular
 */
export default function AnalyticsGeneral(props) {

    let navigate = useNavigate()

    const { plazo, fechas } = props;
    const proyecto = useContext(Proyecto)
    let [data, setData] = useState({
        apartados:
        {
            actuales: 0,
            previas:0,
            porcentaje: 0

        },
        costos: {
            actuales: 0,
            previas: 0,
            porcentaje: 0

        },
        ventas: {
            actuales: 0,
            previas: 0,
            porcentaje: 0
        },
    })


    

    useEffect(() => {
        get()
    }, [proyecto, fechas])




    const get = () => {
        axios.get("/overview", {
            params: {
                fechas: fechas?.length > 0 ? [fechas[0].toDate(), fechas[1].toDate()] : null,
                proyecto_id: proyecto?._id,
            }
        }).then(({ data }) => {
            console.log("data", data);
            setData(data)
        }).catch(error => {
            console.log(error)
            message.error("Error al obtener los datos del overview")
        })
    }

    return <Card className='overview-analytics'>
        <Analytics
            plazo={plazo}
            title="Prospecto Vendidos"
            previas={data.ventas?.previas}
            actuales={data.ventas?.actuales}
            grandient={["#D3FFE7", "#EFFFF6"]}
            icon={({ style, ...iconProps }) => <IconMoneyRecive style={{ ...style, color: "#00AC4F" }} {...iconProps} />}
        />
        <div className="analytics-divider"></div>
        <Analytics
            plazo={plazo}
            title="Visitas Realizadas"
            previas={data.visitas?.previas}
            actuales={data.visitas?.actuales}
            gradient={["#CAF1FF", "#CDF4FF"]}
            icon={({ style, ...iconProps }) => <IconWalletMoney style={{ ...style, color: "#0F5FC2" }} {...iconProps} />}
        />
        <div className="analytics-divider"></div>
        <Analytics
            plazo={plazo}
            title="Coste promedio Lead de Facebook"
            previas={data.costos?.previas}
            actuales={data.costos?.actuales}
            gradient={["#CAF1FF", "#CDF4FF"]}
            icon={({ style, ...iconProps }) => <FaFacebook style={{ ...style, color: "#0F5FC2" }} {...iconProps} />}
            extra={()=>navigate("/admin/costos")}
        />
       
    </Card>
}

