import React, { FC, useState, useEffect } from 'react';
import { Avatar, Popover } from 'antd'
import { TwitterPicker } from "react-color";

// interface OnChangeHandler {
//     (e): void;
// }

// interface MyInputProps {
//     value: string;
//     onChange: OnChangeHandler;
// }

let arrayColores =
    [
        '#FF4D4D', '#4DB380', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#FFB399', '#FF6633', '#99E6E6', '#6666FF'
    ]

const ColorPicker = (props) => {

    const { value, onChange, icon = undefined } = props


    const [color, setColor] = useState(value)

    const onChangeColor = (color) => {
        setColor(color.hex)
        onChange(color.hex)
    }

    useEffect(() => {
        setColor(value)
    }, [value])



    return (
        <div>
            <Popover
                trigger={["click"]}
                content={<TwitterPicker
                    colors={arrayColores}
                    onChange={onChangeColor} />}
            >
                <Avatar className="status-avatar"
                    style={{ backgroundColor: color, marginTop: "5px", cursor: "pointer", ...props.style }}
                    color={color}
                    icon={icon}
                />
            </Popover>
        </div>
    );
}

export default ColorPicker;