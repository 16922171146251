import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import axios from 'axios'

const { Option } = Select


/**
 * @const SelectProspectos
 * @description Select para los prospectos registrados en el sistema
 */
const SelectProspectos = (props) => {

    const {
        value,
        onChange,
        placeholder = "Seleccione el Prospecto",
        onSelect = () => { },
        disabled = false,
        className = "",
        params = {},
        bordered,
        allowClear = true,
        onClear = () => { },
        size = "default",
        suffixIcon = null,
    } = props

    const {
        proyecto_id
    } = params

    const [prospectos, setProspectos] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getProspectos
     * @description Obtiene los prospectos
     */
    const getProspectos = ({ page, limit, search } = prospectos) => {

        axios.get('/prospectos', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setProspectos(data)
        }).catch(error => {
            console.log("ERR, error", error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los prospectos')
        })
    }

    //componentDidMount
    useEffect(() => {
        getProspectos()
    }, [proyecto_id])


    return (
        <Select
            size={size}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search) => getProspectos({ search })}
            onSelect={(item) => {
                onChange(item)
                onSelect(item)
            }}
            value={value}
            onClear={() => {
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            suffixIcon={suffixIcon ? <UserOutlined style={{ fontSize: '18px' }} /> : null}
            options={prospectos?.data?.map(({ _id, nombre }) => ({ value: _id, label: nombre }))}
        >
        </Select>
    );
}



export default SelectProspectos;