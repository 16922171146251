import React, { Component, useContext } from 'react';
import { Button, Modal, message, Row, Col, Form, Spin, Select, Tag, Popover } from 'antd'
import { IconTrash, IconStatus, IconAsesor, IconCloseModal, IconCheck } from '../Iconos'
import Avatar from '../Avatar/Avatar'

import './BtnProspectos.css'
import axios from "axios";
const { Option } = Select;


class AsesorForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			asesores: [],
			loading: false,
		}
	}

	componentDidMount() {
		this.getAsesores()
	}

	/**
	*
	*
	* @memberof ProspectoModal
	* @description Obtiene la lista de estatus del prospecto
	* 
	* @param proyecto_id ObjectId del proyecto actual 
	*/
	getAsesores = () => {
		axios.get('/asesores', {
			params: {
				limit: 100
			}
		})
			.then(res => {
				console.log(res)
				this.setState({
					asesores: res.data.data,
					page_asesor: res.data.page
				})
			})
			.catch(error => {
				console.log('error', error)
			})
	}

	/**
	*
	*
	* @memberof ProspectoModal
	* @description Obtiene la lista de estatus del prospecto
	* 
	* @param proyecto_id ObjectId del proyecto actual 
	*/
	updateProspecto = (values) => {
		if (this.props.prospectos.length === 0) {
			message.error('No hay prospectos seleccionados')
		} else {
			axios.post('/prospectos/update/many', {
				proyecto_id: this.props.proyecto,
				prospectos: this.props.prospectos,
				asesor_id: values.asesor,

			}).then(({ data }) => {
				message.success('Prospectos Actualizados')
				this.props.onCancel()
				this.props.updateProspectos(data.data)
			}).catch(error => {
				console.log(error)
				message.error('Error al actualizar')
			})

		}
	}

	render() {
		return (
			<div >
				<Row >
					<Col span={24}>
						<div>
							<p className="text-center"> Cambiar Asesor</p>
						</div>
					</Col>
					<Col span={24}>
						<Row align="center">
							<p className="txt-gray">Selecciona un Asesor</p>
						</Row>
					</Col>
					<Col xs={24} lg={24} className=" pd-1">
						<Form
							layout={"vertical"}
							requiredMark={false}
						>
							<Spin className="spin" spinning={this.state.loading}>
								<Row gutter={16} className="pd-t-2">
									<Col span={24}>
										<Form.Item
											name="asesor"
											rules={[{
												required: true,
												message: 'Por favor seleccione el Asesor'
											}]}
										>
											<Select
												key={'modal-status'}
												bordered={false}
												showArrow={false}
												mode="multiple"
												placeholder="Asesor"
											>
												{this.state.asesores?.map(asesor => (

													<Option value={asesor._id}>
														<Avatar
															size={30}
															style={{ marginRight: "5px", backgroundColor: asesor?.color }}
															color={asesor?.color}
															image={asesor?.avatar ? axios.defaults.baseURL + "/usuario/" + asesor?._id + "?avatar=true&Authorization=" + sessionStorage.getItem("token") : null}
															name={asesor?.nombre}
														/>

														{asesor.nombre}
													</Option>))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Spin>
						</Form>
					</Col>
				</Row>
			</div>
		)
	}

}

export default function AsesorModal(props) {

	return <Modal
		open={props.visible}
		onCancel={props.onCancel}
		closable={true}
		closeIcon={<div title="Cerrar"> <IconCloseModal /> </div>}
		footer={false}
		destroyOnClose={true}
		mask={true}
		width={300}
		maskClosable={true}

	>
		<AsesorForm {...props} />
	</Modal >

}