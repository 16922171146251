import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, InputNumber, DatePicker} from "antd";

import { AvatarLoader } from "../../Widgets/Uploaders";
//componentes
import axios from "axios";
import moment from "moment";

import ColorPicker from "../../Widgets/FormsComponents/ColorPicker";
import SelectProyecto from "../../Widgets/Inputs/SelectProyecto";
import PhoneInput from "../../Widgets/Inputs/PhoneInput";
import Logged from "../../../Hooks/Logged"

const { RangePicker } = DatePicker;

/**
 * @class ModalProyectos
 * @description Modal para el CRUD de proyectos
 */
class ModalProyectos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: undefined,
            loading: false,
            loadingImage: false,
            estatus: []
        };
    }

    formRef = React.createRef();

    componentDidMount() {
        if (this.props.costo_prospecto_id)
            this.getCosto();
    }


    /**
     * @memeberof ModalProyectos
     * @method getCosto
     * @description Añade un nuevo registro de proyecto
     */
    getCosto = () => {
        this.setState({ loading: true });
        axios.get("/costos_prospecto/" + this.props.costo_prospecto_id)
            .then(({ data }) => {
                data.fechas = [moment(data.fecha_inicio), moment(data.fecha_final)]
                data.proyecto_id = {
                    value: data.proyecto_id._id,
                    label: data.proyecto_id.nombre
                }
                this.formRef.current.setFieldsValue(data)
            })
            .catch((error) => {
                console.log("error", error);
                message.error("Error al obtener el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @memeberof ModalProyectos
     * @method onFinish
     * @description Se ejecuta al hacer submit al formulario
     */
    onFinish = (values) => {

        if(values.fechas?.length > 0){
            values.fecha_inicio = values.fechas[0].toDate();
            values.fecha_final = values.fechas[1].toDate();
        }

        if (this.props.costo_prospecto_id) {
            this.updateCosto(values);
        } else {
            this.addCosto(values);
        }

    };

    /**
     * @method addUser
     * @description Añade un nuevo registro de proyecto
     */
    addCosto = (values) => {
        this.setState({ loading: true });

        axios.post("/costos_prospecto", values)
        .then((response) => {
            message.success("Registro creado");
            this.props.onClose();
        })
        .catch((error) => {
            message.error("Error al crear el registro");
        })
        .finally(() => this.setState({ loading: false }));
    };

    /**
     * @method updateUser
     * @description Actualiza la informacion de un proyecto
     */
    updateCosto = (values) => {

        values.costo_prospecto_id = this.props.costo_prospecto_id

        values.fechas = [moment(values.fecha_inicio), moment(values.fecha_final)]
        values.proyecto_id = values.proyecto_id.value ?? values.proyecto_id;

        this.setState({ loading: true });
        axios.put("/costos_prospecto/", values)
            .then((response) => {
                message.success("Registro actualizado");
                this.props.onClose();
            })
            .catch((error) => {
                message.error("Error al actualizar el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };



    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-proyecto"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row className="w-100" gutter={[16, 0]}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="costo"
                                label="Costo"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese el nombre",
                                    },
                                ]}
                            >
                                <InputNumber 
                                    className="width-100"
                                    addonBefore="$"
                                    precision={2}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={24} lg={24}>
                            <Form.Item
                                name="fechas"
                                label="Fechas"
                            >
                                <RangePicker className="width-100"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="proyecto_id"
                                label="Proyecto"
                                rules={[
                                    {
                                        required: true,
                                        message: "Ingrese la descripción",
                                    },
                                ]}
                            >
                                <SelectProyecto/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

export default function Vista(props) {
    const { visible = false, onClose = () => { } } = props;
    let user = React.useContext(Logged)

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={props.proyecto_id ? "Editar Costo" : "Crear Costo"}
            closable={true}
            destroyOnClose={true}
            zIndex={1000}
            cancelText="Cancelar"
            okText="Guardar"
            okButtonProps={{
                form: "form-proyecto",
                key: "submit",
                htmlType: "submit",
            }}
        >
            <ModalProyectos {...props} user={user} />
        </Modal>
    );
}
