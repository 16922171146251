
import React, { Component, useContext } from 'react';
import { Card, Layout, PageHeader,Button, Modal, message,} from "antd";
import moment from "moment";
import TuiCalendar from 'tui-calendar'; /* ES6 */
import "tui-calendar/dist/tui-calendar.css";
import axios from 'axios';
import "./Schedule.scss";
import { ProyectoId } from '../../../Hooks/Proyecto';
import User from '../../../Hooks/Logged';
import usePermissions from '../../../Hooks/usePermissions';


const { Content, } = Layout;



class Horarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            horarios: {},
            alt: false
        }
    }


    componentDidMount() {
        this.calendarRef.current = new TuiCalendar(this.calendarRef.current, {
            useDetailPopup: false,
            taskView: false,
            calendars: [
                {
                    id: "horarios",
                    name: "horarios",
                }
            ],
            template: {

            },

            week: {
                daynames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
                startDayOfWeek: 1
            },
            disableClick: !this.props.crearCitas && !this.props.eliminarCitas  && !this.props.editarCitas,
            disableDblClick: !this.props.crearCitas && !this.props.eliminarCitas  && !this.props.editarCitas,
        });

        // render schedules
        this.calendarRef.current.clear();
        this.calendarRef.current.render();

        this.calendarRef.current.on("beforeCreateSchedule", (event) => {
            if(!this.props.crearCitas){
                message.info("No tienes permiso para realizar esta acción")
                return
            }

            let id = `${moment(event.start.toDate()).day()}|${moment(event.start.toDate()).format("HH:mm")}|${moment(event.end.toDate()).format("HH:mm")}`
            let title = `${moment(event.start.toDate()).format("hh:mm a")} - ${moment(event.end.toDate()).format("hh:mm a")}`

            event = {
                ...event,
                id,
                title,
                calendarId: "horarios",
                category: "time",
                isVisible: true,
                start: event.start,
                end: event.end,
            }
            console.log("event", event)
            this.calendarRef.current.createSchedules([event])
            this.setState(state => {
                state.horarios[id] = event
                return state
            })

        });

        this.calendarRef.current.on("beforeUpdateSchedule", ({ changes, schedule, end, start }) => {
            if(!this.props.editarCitas){
                message.info("No tienes permiso para realizar esta acción")
                return
            }

            if (this.state.alt) {
                let id = `${moment(start.toDate()).day()}|${moment(start.toDate()).format("HH:mm")}|${moment(end.toDate()).format("HH:mm")}`
                let title = `${moment(start.toDate()).format("hh:mm a")} - ${moment(end.toDate()).format("hh:mm a")}`

                let event = {
                    ...schedule,
                    id,
                    title,
                    calendarId: "horarios",
                    category: "time",
                    isVisible: true,
                    start,
                    end
                }
                this.calendarRef.current.createSchedules([event])
                this.setState(state => {
                    state.horarios[id] = event
                    return state
                })
            } else {
                let title = `${moment(start.toDate()).format("hh:mm a")} - ${moment(end.toDate()).format("hh:mm a")}`
                this.calendarRef.current.updateSchedule(schedule.id, "horarios", { ...changes, title })
                this.setState(state => {
                    state.horarios[schedule.id] = {
                        ...schedule,
                        ...changes,
                        title
                    }
                    return state
                })
            }
        })

        this.calendarRef.current.on("clickSchedule", ({ schedule }) => {
            if(!this.props.eliminarCitas){
                message.info("No tienes permiso para realizar esta acción")
                return
            }
            Modal.confirm({
                title: "¿Deseas eliminar este rango de horas?",
                onOk: () => {
                    this.calendarRef.current.deleteSchedule(schedule.id, "horarios")
                    this.setState(state => {
                        delete state.horarios[schedule.id]
                        return state
                    })
                }
            })

        });

        document.addEventListener('keyup', this.keyUp);
        document.addEventListener('keydown', this.keyDown);
        this.getProyecto()
    }

    componentDidUpdate(prevProps) {
        if (this.props.proyecto_id !== prevProps.proyecto_id) {
            console.log("update", this.props.proyecto_id)
            this.calendarRef.current.clear()
            this.getProyecto()
        }
    }

    keyUp = (e) => {
        if (e.altKey == false && this.state.alt == true) {
            this.setState({ alt: false })
        }
    }
    keyDown = (e) => {
        if (e.altKey == true && this.state.alt == false) {
            this.setState({ alt: true })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.keyUp);
        document.removeEventListener('keydown', this.keyDown);
    }

    getProyecto = () => {
        this.setState({ loading: true })
        axios.get('/proyectos/' + this.props.proyecto_id).then(response => {
            let schedules = []
            let stateHorarios = {}
            try {

                for (let [dia, horarios] of Object.entries(response?.data?.horarios)) {
                    dia = moment().day(dia)
                    for (const horario of horarios) {

                        console.log(dia.format("YYYY-MM-DD" + " " + horario.hora_inicio))
                        let start = moment(dia.format("YYYY-MM-DD" + " " + horario.hora_inicio))
                        let end = moment(dia.format("YYYY-MM-DD" + " " + horario.hora_fin))

                        let id = `${start.day()}|${start.format("HH:mm")}|${end.format("HH:mm")}`
                        let title = `${start.format("hh:mm a")} - ${end.format("hh:mm a")}`
                        let event = {
                            id,
                            title,
                            calendarId: "horarios",
                            category: "time",
                            isVisible: true,
                            isAllDay: false,
                            start: start.toDate(),
                            end: end.toDate(),
                        }
                        schedules.push(event)
                        stateHorarios[id] = {
                            ...event, start, end
                        }
                    }
                }
                console.log("schedules",schedules)

            } catch (error) {

            }
            this.calendarRef.current.createSchedules(schedules)
            this.setState({ proyecto: response.data, horarios: stateHorarios })

        }).catch(error => {
            console.log(error)
            message.error('Error al traer el proyecto')
            this.setState({proyecto: undefined})
        }).finally(() => this.setState({ loading: false }))

    }

    guardarProyecto = () => {
        let { horarios } = this.state
        let dias = {}
        for (const horario of Object.values(horarios)) {
            let diaSemana = moment(horario.start.toDate()).day()
            if (!dias[diaSemana])
                dias[diaSemana] = []
            dias[diaSemana].push({
                hora_inicio: moment(horario.start.toDate()).format("HH:mm"),
                hora_fin: moment(horario.end.toDate()).format("HH:mm"),
            })
        }
        axios.put('/proyectos/' + this.props.proyecto_id, {
            horarios: dias
        }).then(response => {
            console.log("response update", response)
            message.success('¡Proyecto Actualizado!')
        }).catch(error => {
            console.log(error)
            message.error('Error al actualizar el proyecto')
        }).finally(() => this.setState({ loading: false }))
    }


    calendarRef = React.createRef()

    render() {

        return (
            <Content className="content hm-calendar hm-layout-content" >
                <Card size='small' className='card-header'>
                    <PageHeader className="site-page-header hm-page-header cnt-page-header"
                        title={<span className="ant-page-header-heading-title" title="">Horarios {this.state.proyecto ? <>de <i>{this.state.proyecto?.nombre}</i></> : ""}</span>}
                        extra={<Button type='primary' disabled={!this.props.editProyectos} className="btn-mdl-save" onClick={() => this.guardarProyecto()}>Guardar Horario</Button>}
                    />
				</Card>
                <Card className="hm-card">
                    <div ref={this.calendarRef} />
                </Card>
            </Content>
        )
    }
}

export default function Vista(props) {

    let proyecto_id = useContext(ProyectoId)
    const user = useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearCitas: ['citas', 'create'],
        editarCitas: ['citas', 'edit'],
        eliminarCitas: ['citas', 'delete'],
        editProyectos: ['proyectos', 'edit']
    })

    return (
        <Horarios {...props} proyecto_id={proyecto_id} {...permisos}/>
    )

}