import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import CardProspecto from '../CardProspecto';


export default class Task extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {

    if(!Number.isInteger(this.props.index) || !Number.isInteger(this.props.task?.prioridad)) return null
    
    return (
      <Draggable isDragDisabled={this.props.probabilidad} draggableId={this.props.task._id} index={this.props.probabilidad ? this.props.index : this.props.task?.prioridad} >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <CardProspecto
              task={this.props.task}
              onClick={this.props.modalVisible}
              _id={this.props.task._id}
              index={this.props.index}
              title={this.props.task.nombre}
              email={this.props.task.email}
              telefono={this.props.task.telefono}
              fecha={this.props.task.fecha}
              porcentaje={this.props.task.probabilidad}
              asesores={this.props.task.asesores}
              proyecto={this.props.task.proyecto}
              fuente={this.props.task.fuente}
              loading={this.props.task.loading}
              citas={this.props.task.citas}
              prioridad={this.props.task?.prioridad}
            />
          </div>
        )}
      </Draggable>
    );
  }
}